import React, {useEffect, useState} from 'react';
import {Box, Modal, Typography, useMediaQuery, Checkbox, FormControlLabel, FormControl} from '@mui/material';
import ItemList from './common/ItemList';
import ItemDetails from './common/Details';
import {addLeaderApi, deleteUser, getAdminLeadersGroupApi, getAdminOrgDisciplesApi, getAdminPromoteDiscipleApi, getLeaderDiscipleDetailsApi, inviteUser, promoteUserHandlerApi} from '../../api';
import useAuthStore from '../../store/authStore';
import AdminStructure from './common/AdminStructure';
import {validateEmail} from '../../utils';
import InviteUser from '../../components/modules/InviteUser';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';
import useTranslations from '../../hooks/useTranslation';
import ModalCop from '../../components/common/Modal';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import StyledSelectInput from './components/StyledSelectInput';
import AddDisciple from './components/AddDisciple';

const mdStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  minHeight: 500,
  bgcolor: '#9e0001',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  outline: 'none'
};

const smallerStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 350,
  minHeight: 250,
  bgcolor: '#9e0001',
  borderRadius: '8px',
  boxShadow: 24,
  p: 2,
  outline: 'none'
};

function Leaders() {
  const {translate} = useTranslations();
  const navigate = useNavigate();
  const {selectedOrganization} = useAuthStore(state => state);
  const [selectedLeader, setSelectedLeader] = useState(null);
  const [adminLeaders, setAdminLeaders] = useState([]);
  const [searchLeaders, setSearchLeaders] = useState([]);
  const [selectedModalOption, setSelectedModalOption] = useState('Invite Leader');
  const [formDataList, setFormDataList] = useState([]);
  const [adminData, setAdminData] = useState({
    name: '',
    email: ''
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [discipleData, setDiscipleData] = useState([]);
  const [nominatedDisciple, setNominatedDisciple] = useState([]);
  const [loader, setLoader] = useState(false);
  const [leaderDetails, setLeaderDetails] = useState({
    groups_led: 0,
    total_groups_member: 0,
    disciples_made: 0,
    disciples_dropped: 0
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [buttonLoader, setButtonLoader] = useState(false);

  const [isAddLeaderOpen, setIsAddLeaderOpen] = useState(false);
  const [selectDisciple, setSelectDisciple] = useState('');
  const [leaderData, setLeaderData] = useState({
    name: '',
    phone: '',
    email: ''
  });
  const [disciplesList, setDisciplesList] = useState([]);
  const [notify, setNotify] = useState(false);
  const [groupLevel, setGroupLevel] = useState({
    D1: false,
    D2: false,
    D3: false,
    NA: false
  });

  const handleDelete = async () => {
    try {
      setButtonLoader(true);
      const response = await deleteUser(deletingId);
      if (response?.data?.status === 200) {
        setIsModalOpen(false);
        getLeaders();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };

  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked);
  };
  const handleMenuClose = e => {
    if (e) {
      e.stopPropagation();
    }
    setIsModalOpen(false);
    setIsAddLeaderOpen(false);
  };

  const resetData = () => {
    setLeaderData({
      name: '',
      phone: '',
      email: ''
    });
    setNotify(false);
    setGroupLevel({D1: false, D2: false, D3: false, NA: true});
    setSelectDisciple('');
  };
  const handleAddLeader = async () => {
    let res;
    try {
      setButtonLoader(true);
      if (selectDisciple) {
        const data = {
          discipleId: selectDisciple,
          is_notify: notify,
          level: Object.keys(groupLevel)
            .filter(key => groupLevel[key])
            .join(','),
          organization_id: selectedOrganization.id,
          user_role: 'DISCIPLE'
        };
        res = await promoteUserHandlerApi(selectDisciple, data);
      } else {
        const data = {
          first_name: leaderData.name.split(' ')[0] || '',
          last_name: leaderData.name.split(' ')[1] || '',
          email: leaderData.email,
          mobile_number: leaderData.phone,
          send_invite: true,
          organization_id: selectedOrganization.id,
          level: Object.keys(groupLevel)
            .filter(key => groupLevel[key])
            .join(',')
        };
        res = await addLeaderApi(data);
      }
      if (res.data.status === 200) {
        setIsAddLeaderOpen(false);
        resetData();
        getLeaders();
        getDisciples();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };
  const handlePromoteChange = name => {
    if (name === 'NA') {
      setGroupLevel({D1: false, D2: false, D3: false, NA: true});
    }
    if (name === 'D1') {
      setGroupLevel({D1: true, D2: false, D3: false, NA: false});
    }
    if (name === 'D2') {
      setGroupLevel({D1: true, D2: !groupLevel.D2, D3: false, NA: false});
    }
    if (name === 'D3') {
      setGroupLevel({D1: true, D2: true, D3: !groupLevel.D3, NA: false});
    }
  };

  const getDisciples = async () => {
    try {
      const resp = await getAdminPromoteDiscipleApi(selectedOrganization.id);

      if (resp?.data?.status == 200) {
        setDisciplesList(
          resp?.data?.data?.map(disciple => ({
            label: disciple.last_name ? `${disciple.first_name} ${disciple.last_name}` : disciple.first_name,
            value: disciple.id
          }))
        );
      }
    } catch (error) {}
  };

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleLeaderClick = async leader => {
    setLoader(true);
    setSelectedLeader(leader);
    const resp = await getLeaderDiscipleDetailsApi(leader?.id, 'leader', selectedOrganization.id);
    if (resp.status == 200) {
      setDiscipleData(resp?.data?.data);
      setLoader(false);
    }
    setLoader(false);
  };

  const getLeaders = async () => {
    try {
      setSelectedLeader(null);
      setLoadingPage(true);
      const resp = await getAdminLeadersGroupApi(selectedOrganization.id);

      if (resp?.data?.status == 200) {
        setAdminLeaders(resp?.data?.data);
        setSearchLeaders(resp?.data?.data);
      }
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
    }
  };

  const getLeaderGroups = async leader => {
    setLoader(true);
    try {
      const resp = await getAdminLeadersGroupApi(leader?.id, 'details', selectedOrganization.id);
      setLeaderDetails(resp?.data?.data);
      setLoader(false);
    } catch (error) {}
    setLoader(false);
  };

  useEffect(() => {
    let firstActiveLeader;
    if (selectedLeader) {
      firstActiveLeader = selectedLeader;
    } else {
      firstActiveLeader = adminLeaders.find(leader => leader.status === 'ACTIVE');
    }
    if (firstActiveLeader) {
      handleLeaderClick(firstActiveLeader);
      getLeaderGroups(firstActiveLeader);
    }
  }, [adminLeaders]);

  const inviteLeader = async (adminDetails, type) => {
    try {
      setLoading(true);
      const nameParts = adminDetails.name?.split(' ');
      const first_name = nameParts[0];
      const last_name = nameParts[1] || '';
      const data = {
        first_name,
        last_name,
        email: adminDetails.email,
        organization_ids: [selectedOrganization.id]
      };
      const response = await inviteUser(data);
      if (response?.data?.status === 200) {
        const userId = response?.data?.data?.id;
        if (type === 'continue') {
          setFormDataList([]);
          handleClose();
          getLeaders();
        } else {
          setFormDataList(() => [...formDataList, {...adminData, user_id: userId}]);
        }
        setAdminData({
          name: '',
          email: ''
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFormDataList([]);
    }
  };

  const onAddLeader = async () => {
    if (!adminData.email && !adminData.name.trim()) {
      setError(translate('All fields are required!'));
    }
    if (!adminData.email && adminData.name.trim()) {
      setError(translate('All fields are required!'));
    }
    if (adminData.email && !adminData.name.trim()) {
      setError(translate('All fields are required!'));
    }
    if (!validateEmail(adminData.email)) {
      return;
    }
    if (adminData.name && adminData.email) {
      inviteLeader(adminData, 'add');
    }
  };

  const continueHandler = () => {
    if (!adminData.email && !adminData.name && formDataList.length === 0) {
      setError(translate('Please add at-least one leader!'));
    } else if (!adminData.email && adminData.name.trim()) {
      setError(translate('All fields are required!'));
    } else if (adminData.email && !adminData.name.trim()) {
      setError(translate('All fields are required!'));
    } else {
      if (validateEmail(adminData.email) && adminData.name.trim()) {
        inviteLeader(adminData, 'continue');
      } else {
        if (formDataList.length > 0) {
          setOpen(false);
          setFormDataList([]);
          setAdminData({name: '', email: ''});
        }
      }
    }
  };

  const getNominatedDisciples = async () => {
    try {
      setNominatedDisciple([]);
      const res = await getAdminOrgDisciplesApi(selectedOrganization.id, 'disciple', true);
      if (res?.data?.status === 200) {
        setNominatedDisciple(res?.data?.data);
      }
    } catch (error) {}
  };
  const searchHandler = value => {
    if (value.length === 0) {
      setAdminLeaders(searchLeaders);
    } else {
      const filteredLeaders = searchLeaders.filter(leader => leader?.name?.toLowerCase().includes(value.toLowerCase()));
      setAdminLeaders(filteredLeaders);
    }
  };

  useEffect(() => {
    getLeaders();
    getDisciples();
  }, [selectedOrganization]);

  useEffect(() => {
    if (selectedModalOption === 'Promote Nominated Disciples') {
      getNominatedDisciples();
    }
  }, [selectedModalOption]);

  // Calculate the counts of each status leader
  const activeLeadersCount = adminLeaders ? adminLeaders?.filter(leader => leader.status === 'ACTIVE')?.length : 0;
  const invitedLeadersCount = adminLeaders ? adminLeaders?.filter(leader => [`INVITED`].includes(leader.status))?.length : 0;

  return (
    <>
      <Box>
        <div
          style={{
            backgroundImage: 'none !important',
            backgroundColor: '#f9f9f9',
            height: '100vh',
            width: '100vw',
            overflowY: isSmallScreen ? 'auto' : 'hidden',
            overflowX: 'hidden'
          }}
        >
          <AdminStructure
            isSearch={true}
            searchHandler={searchHandler}
            handleAddClick={() => {
              handleOpen(), setSelectedModalOption('Invite Leader');
              // Comment for now & change later
              // setIsAddLeaderOpen(true);
            }}
            heading={translate('Leaders')}
            isAddingUser={true}
          >
            {loadingPage ? (
              <Loader color="#9e0001" />
            ) : adminLeaders.length === 0 ? (
              <NoDataFoundMessage mt={5} message={translate('There are no leaders in this organization')} />
            ) : (
              <Box
                display={'flex'}
                // justifyContent={"space-between"}
                px={2}
                gap={2}
                sx={{
                  height: {
                    md: '88vh',
                    xs: 'auto',
                    sm: 'auto'
                  },
                  flexDirection: {
                    md: 'row',
                    sm: 'column',
                    xs: 'column'
                  },
                  justifyContent: {
                    md: 'space-between'
                  }
                }}
              >
                <Box width="85%" display={'flex'} flexDirection={'column'} gap={2}>
                  <ItemList
                    items={adminLeaders.filter(leader => ['ACTIVE'].includes(leader.status))}
                    handleClick={e => {
                      handleLeaderClick(e);
                      getLeaderGroups(e);
                    }}
                    selectedItem={selectedLeader}
                    height={'70%'}
                    // deleteIcon="true"
                    reloadData={getLeaders}
                    title={`${translate('Active Leaders')} (${activeLeadersCount})`}
                  />
                  <ItemList
                    items={adminLeaders.filter(leader => [`INVITED`].includes(leader.status))}
                    handleClick={e => {
                      handleLeaderClick(e);
                      getLeaderGroups(e);
                    }}
                    selectedItem={selectedLeader}
                    height={'30%'}
                    // deleteIcon="true"
                    reloadData={getLeaders}
                    title={`${translate('Invited Leaders')} (${invitedLeadersCount})`}
                  />
                </Box>
                <Box
                  width="68%"
                  display="flex"
                  flexDirection="column"
                  gap={2}
                  sx={{
                    justifyContent: {
                      md: 'space-between'
                    }
                    // paddingX: {
                    //   md: 2,
                    // },
                  }}
                  // px={2}
                >
                  {selectedLeader && (
                    <ItemDetails
                      title={selectedLeader.name}
                      selectedLeader={selectedLeader}
                      // change later
                      // editIcon={true}
                      deleteIcon={true}
                      setIsModalOpen={() => {
                        setIsModalOpen(true);
                        setDeletingId(selectedLeader.id);
                      }}
                      setIsEditModalOpen={setIsEditModalOpen}
                      details={[
                        {
                          name: 'Year Completed:',
                          detail: leaderDetails?.year_completed
                        },
                        {
                          name: translate('Number of Groups Led:'),
                          detail: leaderDetails?.groups_led
                        },
                        {
                          name: translate('Total Group Members:'),
                          detail: leaderDetails?.total_groups_member,
                          mb: '8px'
                        },
                        {
                          name: translate('Disciples Made:'),
                          detail: leaderDetails?.disciples_made
                        },
                        {
                          name: translate('Disciples Dropped:'),
                          detail: leaderDetails?.disciples_dropped,
                          mb: '6px'
                        },
                        {
                          name: translate('Leader Last Login Time:'),
                          detail: leaderDetails?.last_login ? moment(leaderDetails?.last_login).format('dddd, MMMM Do YYYY HH:mm:ss') : translate('No login data available')
                        },
                        {
                          name: leaderDetails?.approved_level && leaderDetails?.approved_level !== 'NA' ? `${translate('Approved to Lead')} ${leaderDetails?.approved_level.split(',').pop()}` : translate('Currently not allowed to lead any group'),
                          icon: selectedLeader?.status == 'COMPLETED',
                          fontWeight: 600,
                          promote: true,
                          spaceBetween: true
                        }
                      ]}
                      height="40%"
                      loader={loader}
                      reloadData={() => {
                        handleLeaderClick(selectedLeader);
                        getLeaderGroups(selectedLeader);
                      }}
                      approvedLevel={leaderDetails?.approved_level}
                    />
                  )}
                  {selectedLeader && <ItemDetails handleClick={item => navigate(`/admin-dashboard/disciples#${item?.id}`)} title={translate('Disciples {leaderName} has led').replace('{leaderName}', selectedLeader.name)} selectedLeader={selectedLeader} details={discipleData} fontWeight="600" height="44%" loader={loader} />}
                </Box>
              </Box>
            )}
          </AdminStructure>
        </div>
      </Box>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={isSmallScreen ? smallerStyle : mdStyle}>
          <Box mt={4}>
            <InviteUser continueHandler={continueHandler} onSubmit={onAddLeader} formDataList={formDataList} setFormDataList={setFormDataList} setData={setAdminData} data={adminData} loading={loading} setError={setError} error={error} heading={`${translate('Invite leader(s) for ')}${selectedOrganization?.name} ${translate('organization?')}`} buttonTextOne={translate('Add Another Invitation')} buttonTextTwo={translate('Send Invitation(s)')} />
          </Box>
        </Box>
      </Modal>
      {/* Modal for delete Leader */}
      <ModalCop
        open={isModalOpen}
        onClose={handleMenuClose}
        title={translate('Delete Leader')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setIsModalOpen(false);
              setDeletingId(null);
              setIsChecked(false);
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: translate('Delete'),
            onClick: handleDelete,
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            disabled: !isChecked || buttonLoader,
            loading: buttonLoader
          }
        ]}
      >
        <Typography sx={{mb: 2, color: '#000000'}}>{translate('Are you certain that you want to delete this leader? All information will be removed from the system. This action cannot be undone!')}</Typography>
        <FormControlLabel
          sx={{'& .MuiFormControlLabel-label': {color: '#000000', fontSize: '16px', fontWeight: 550}}}
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              sx={{
                color: '#000000',
                '&.Mui-checked': {
                  color: '#000000'
                }
              }}
            />
          }
          label={translate('I am sure that I want to delete this user.')}
        />
      </ModalCop>

      {/* modal for Add leader */}
      <ModalCop
        open={isAddLeaderOpen}
        onClose={handleMenuClose}
        title={translate('Promote Existing Disciple to Leader')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setIsAddLeaderOpen(false);
              resetData();
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: translate('Save'),
            onClick: () => handleAddLeader(),
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            loading: buttonLoader,
            disabled: buttonLoader || !(selectDisciple || (leaderData.name.trim() && validateEmail(leaderData.email.trim()))) || !(groupLevel.D1 || groupLevel.D2 || groupLevel.D3)
          }
        ]}
      >
        <FormControl fullWidth>
          <StyledSelectInput firstOption={{value: '', label: translate('No Selection')}} optionArray={disciplesList} labelId="disciple-select-label" id="disciple-select" value={selectDisciple} onChange={e => setSelectDisciple(e.target.value)} displayEmpty />
        </FormControl>
        <Typography variant="body2" sx={{mt: -2, color: '#888888'}}>
          {translate('Select Existing Disciple')}
          <span style={{color: '#d32f2f', marginLeft: '2px'}}>*</span>
        </Typography>
        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              color: '#000000 !important'
            }
          }}
          control={
            <Checkbox
              checked={notify}
              onChange={e => setNotify(e.target.checked)}
              sx={{
                color: '#000000 !important', // Unchecked color
                '&.Mui-checked': {
                  color: '#000000 !important' // Checked color
                }
              }}
            />
          }
          label={translate('Send Notification to Disciple')}
        />
        <Typography fontWeight="800" fontSize={'16px'} color={'#000000'} mb={2}>
          {translate('OR')}
        </Typography>
        <hr style={{border: '0.5px solid #000000', width: '99%', margin: '2px auto'}} />

        <Typography fontWeight="800" fontSize={'16px'} color={'#d32f2f'} mb={2}>
          {translate('Invite New Leader')}
        </Typography>
        <AddDisciple
          setFormData={setLeaderData}
          formData={leaderData}
          disabled={selectDisciple}
          labels={{
            nameLabel: translate('Leader Name')
          }}
        />
        <hr style={{border: '0.5px solid #000000', width: '99%', margin: '2px auto'}} />
        <Typography fontWeight="800" fontSize={'16px'} color={'#d32f2f'} mb={2}>
          {translate('New Leader Approved to Lead')}
        </Typography>
        <Box sx={{display: 'flex', flexDirection: 'row', gap: '4px'}}>
          <FormControlLabel
            sx={{'& .MuiFormControlLabel-label': {color: '#000000'}, mb: 0}}
            control={
              <Checkbox
                checked={groupLevel.D1}
                onChange={e => handlePromoteChange('D1')}
                sx={{
                  color: '#000000',
                  '&.Mui-checked': {
                    color: '#000000'
                  },
                  py: 0.2
                }}
              />
            }
            label={translate('D1')}
          />
          <FormControlLabel
            sx={{'& .MuiFormControlLabel-label': {color: '#000000'}, mb: 0}}
            control={
              <Checkbox
                checked={groupLevel.D2}
                onChange={e => handlePromoteChange('D2')}
                sx={{
                  color: '#000000',
                  '&.Mui-checked': {
                    color: '#000000'
                  },
                  py: 0.2
                }}
              />
            }
            label={translate('D2')}
          />
          <FormControlLabel
            sx={{'& .MuiFormControlLabel-label': {color: '#000000'}, mb: 0}}
            control={
              <Checkbox
                checked={groupLevel.D3}
                onChange={e => handlePromoteChange('D3')}
                sx={{
                  color: '#000000',
                  '&.Mui-checked': {
                    color: '#000000'
                  },
                  py: 0.2
                }}
              />
            }
            label={translate('D3')}
          />
        </Box>
      </ModalCop>
    </>
  );
}

export default Leaders;
