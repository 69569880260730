import React, {useEffect, useState} from 'react';
import UserContainer from '../components/common/UserContainer';
import {useNavigate, useParams} from 'react-router-dom';
import {Alert, Avatar, Grid, Typography, Box, IconButton} from '@mui/material';
import {FILE_PATH_URL} from '../constants';
import {getGroupApi, getGroupDiscipleApi, markAttendenceOfGroupMeeting, getAttendenceOfGroupMeeting} from '../api';
import moment from 'moment';
import HeaderContainer from '../components/common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from '../components/common/Loader';
import CheckBoxInput from '../components/common/CheckBoxInput';
import useTranslations from '../hooks/useTranslation';
import ManuallyInvitedModal from '../components/common/ManuallyInvitedModal';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';

const DiscipleItem = ({disciple, attendance, handleCheckboxChange}) => {
  const isManuallyActivated = disciple?.is_manually_activated == 1 || disciple?.is_manually_activated == true;
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <Box py={2} ml={2} display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" sx={{ width: '95%'}}>
        <Box display="flex" alignItems="center" width={'100%'} marginRight={'10px'}>
          <CheckBoxInput checked={attendance.includes(disciple?.disciple_id)} onChange={() => handleCheckboxChange(disciple?.disciple_id)} disabled={true} />
          <Avatar sx={{width: 56, height: 56, ml: 2}} alt={disciple?.first_name} src={disciple?.avatar} />
          <Box textAlign="left" ml={2}>
            <Typography variant="h5" fontWeight={600}>
              {disciple?.first_name || '' + ' ' + disciple?.last_name || ''}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" alignItems="end">
          {isManuallyActivated && (
            <IconButton onClick={() => setIsModalOpen(true)} style={{cursor: 'pointer'}}>
              <SpeakerNotesOffOutlinedIcon style={{color: 'white', fontSize: '2rem'}} />
            </IconButton>
          )}
        </Box>
      </Box>
      <ManuallyInvitedModal bgColor="#fff" textColor="#000" titleColor="#000" setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
    </Box>
  );
};

const Attendance = () => {
  const {translate} = useTranslations();
  const [groupData, setGroupData] = useState([]);
  const [disciples, setDisciples] = useState([]);
  const [attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const params = useParams();
  const navigate = useNavigate();

  const getGroupDetailsHandler = async () => {
    try {
      const res = await getGroupDiscipleApi(params?.id);
      if (res?.data?.status === 200) {
        const activeDisciples = res?.data?.data.filter(disciple => disciple.user_status === 'ACTIVE');
        setDisciples(activeDisciples);
      }
    } catch (error) {
      // setError('Failed to fetch group disciples');
    }
  };

  const getGroupHandler = async () => {
    try {
      const res = await getGroupApi(params?.id);
      if (res?.data?.status === 200) {
        setGroupData(res?.data?.data);
      }
    } catch (error) {
      // setError('Failed to fetch group data');
    }
  };

  const handleCheckboxChange = discipleId => {
    setAttendance(prev => {
      if (prev.includes(discipleId)) {
        return prev.filter(id => id !== discipleId);
      } else {
        return [...prev, discipleId];
      }
    });
  };

  const handleSubmitAttendance = async () => {
    const data = {
      meeting_id: params?.meetingId,
      attendese: attendance
    };
    try {
      const res = await markAttendenceOfGroupMeeting(data);
      if (res?.data?.status === 200) {
        navigate(-1);
      }
    } catch (error) {
      // setError('Failed to submit attendance');
    }
  };

  const getAttendance = async () => {
    try {
      const res = await getAttendenceOfGroupMeeting(params?.meetingId);
      if (res?.data?.status === 200) {
        const result = res.data.data.attendance;
        const attendanceArray = result
          .split(',')
          .map(Number)
          .filter(id => id !== 0);
        setAttendance(attendanceArray);
      }
    } catch (error) {
      // setError('Failed to submit attendance');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([getGroupHandler(), getGroupDetailsHandler(), getAttendance()]);
      setLoading(false);
    };
    fetchData();
  }, []);

  return (
    <>
      <UserContainer onClick={() => navigate('/create-group')} addText={translate('Add Disciple')} isShow2={false} headingText={groupData?.name ? `${groupData?.name}${translate("'s Group")}` : ''} subHeadingText={groupData?.group_meeting?.meeting_time ? `${moment(new Date(groupData?.group_meeting?.meeting_time)).format('h:mm A')} ${translate('on')} ${groupData?.group_meeting?.meeting_day} ${translate('at')} ${groupData?.group_meeting?.meeting_place}` : ''} filePath={`${FILE_PATH_URL}/${groupData?.avatar}`} isShow={false}>
        <HeaderContainer textAlign="left" m={2} variant="h5" fontWeight={600}>
          <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => navigate(-1)} color="white" />
          <Typography textAlign="left" ml={2} variant="h5" fontWeight={600}>
            {translate('Attendance')}
          </Typography>
        </HeaderContainer>
        {loading ? (
          <Loader />
        ) : disciples && disciples.length > 0 ? (
          <Box>
            {disciples.map(disciple => (
              <DiscipleItem key={disciple.disciple_id} disciple={disciple} attendance={attendance} handleCheckboxChange={handleCheckboxChange} />
            ))}
          </Box>
        ) : (
          <Box marginTop={10}>
            <Typography variant="h5" marginBottom={4}>
              {translate('Invite your first people!')}
            </Typography>
            <Typography variant="body1">{translate('Once a disciple is added manually or accepts an invitation, they will be added here.')}</Typography>
          </Box>
        )}
        <Grid item sx={{height: '50px'}}>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
        {/* {disciples && disciples.length > 0 && (
          <Box m={2}>
            <Button sx={{fontWeight: 400}} onClick={handleSubmitAttendance} variant="contained" color="secondary">
              {translate("Submit Attendance")}
            </Button>
          </Box>
        )} */}
      </UserContainer>
    </>
  );
};

export default Attendance;
