import {CONSTANTS} from '../constants';

const useTranslations = () => {
  const loadAllTranslations = async (lan = 'en') => {
    try {
      // Extract value
      const languageCode = typeof lan === 'object' ? lan.value : lan;
      const response = await fetch(`/locales/${languageCode}.json`);
      const data = await response.json();
      localStorage.setItem('translation', JSON.stringify(data));
      // window.location.reload();
    } catch (error) {
      console.error('Error loading translation files:', error);
    }
  };

  const translate = key => {
    const storedTranslations = localStorage.getItem('translation');
    const translations = storedTranslations ? JSON.parse(storedTranslations) : {};
    return translations[key] || key;
  };

  const switchLanguage = lang => {
    const selectedLang = CONSTANTS.LANG[lang];
    // Store selected language and direction
    localStorage.setItem('selectedLang', selectedLang.value);
    localStorage.setItem('direction', selectedLang.value === 'ur' ? 'rtl' : 'ltr');

    // Apply changes before reload
    document.documentElement.setAttribute('lang', selectedLang.value);
    document.documentElement.setAttribute('dir', selectedLang.value === 'ur' ? 'rtl' : 'ltr');

    // loadAllTranslations(selectedLang.value);
    loadAllTranslations(selectedLang.value).then(() => {
      window.location.reload(); // Ensure UI updates instantly
    });
  };

  return {loadAllTranslations, translate, switchLanguage};
};

export default useTranslations;
