import {Navigate, Route, Routes} from 'react-router-dom';
import Home from '../pages/Home';
import Journey from '../pages/Journey';
import CreateGroup from '../pages/CreateGroup';
import MainContainer from '../components/common/MainContainer';
import LeaderHome from '../pages/LeaderHome';
import SuperAdmin from '../pages/SuperAdmin';
import Login from '../components/modules/Auth/Login';
import Register from '../components/modules/Auth/Register';
import Assessment from '../pages/Assesment';
import GroupEmail from '../pages/GroupEmail';
import EditGroup from '../pages/EditGroup';
import ForgotPassword from '../pages/ForgotPassword';
import DiscipleHome from '../pages/DiscipleHome';
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import EditProfile from '../pages/EditProfile';
import ContactUs from '../pages/ContactUs';
import StartMeeting from '../pages/StartMeeting.jsx';
import ReadingPlan from '../pages/ReadingPlan.jsx';
import EmailLeader from '../pages/EmailLeader.jsx';
import GetStarted from '../pages/GetStarted.jsx';
import Groups from '../pages/admin/Groups.jsx';
import Leaders from '../pages/admin/Leaders.jsx';
import Disciples from '../pages/admin/Disciples.jsx';
import Admins from '../pages/admin/Admins.jsx';
import Settings from '../pages/admin/Settings.jsx';
import Promotions from '../pages/admin/Promotions.jsx';
import Reports from '../pages/admin/Reports.jsx';
import Dashboard from '../pages/admin/Dashboard.jsx';
import Attendance from '../pages/Attendance.jsx';
import MarkAttendance from '../pages/MarkAttendance.jsx';
import ChangePassword from '../pages/ChangePassword.jsx';
import AdminChangePassword from '../pages/admin/AdminChangePassword.jsx';
import ReadingPlans from '../pages/admin/ReadingPlans.jsx';
import Organisations from '../pages/superadmin/Organisations.jsx';
import AdminProfile from '../pages/admin/components/AdminProfile.jsx';
import DraftDay from '../pages/admin/DraftDay.jsx';

const publicRoutes = [
  {
    id: 1,
    path: '/login',
    element: <Login />
  },
  {
    id: 2,
    path: '/signup',
    element: <Register />
  },
  {
    id: 3,
    path: '/forgot-password',
    element: <ForgotPassword />
  },
  {
    id: 4,
    path: '/get-started',
    element: <GetStarted />
  },
  {
    id: 5,
    path: '*',
    element: <Navigate to="/get-started" />
  }
];
const superadminRoutes = [
  // {
  //   id: 1,
  //   path: '/super-admin',
  //   element: <SuperAdmin />
  // },
  {
    id: 2,
    path: '/super-admin/dashboard',
    element: <Dashboard isSuperAdmin={true} />
  },
  {
    id: 3,
    path: '/super-admin/organization',
    element: <Organisations />
  },
  {
    id: 4,
    path: '/super-admin/reading-plans',
    element: <ReadingPlans role="SUPERADMIN" />
  },
  {
    id: 5,
    path: '/super-admin/reports',
    element: <Reports />
  },
  {
    id: 7,
    path: '*',
    element: <Navigate to="/super-admin" />
  }
];
const adminRoutes = [
  {
    id: 1,
    path: '/admin-dashboard/groups',
    element: <Groups />
  },
  {
    id: 2,
    path: '/admin-dashboard/leaders',
    element: <Leaders />
  },
  {
    id: 3,
    path: '/admin-dashboard/disciples',
    element: <Disciples />
  },
  {
    id: 4,
    path: '/admin-dashboard/admins',
    element: <Admins />
  },
  {
    id: 5,
    path: '/admin-dashboard/reading-plans',
    element: <ReadingPlans role="ADMIN" />
  },
  {
    id: 6,
    path: '/admin-dashboard/settings',
    element: <Settings />
  },
  {
    id: 7,
    path: '/admin-dashboard/promotions',
    element: <Promotions />
  },
  {
    id: 8,
    path: '/admin-dashboard/reports',
    element: <Reports />
  },
  {
    id: 9,
    path: '/admin-dashboard',
    element: <Dashboard isAdmin={true} />
  },
  {
    id: 10,
    path: '*',
    element: <Navigate to="/admin-dashboard" />
  },
  {
    id: 11,
    path: '/admin-dashboard/change-password',
    element: <AdminChangePassword />
  },
  {
    id: 12,
    path: '/admin-dashboard/change-password',
    element: <AdminChangePassword />
  },
  {
    id: 12,
    path: '/admin-dashboard/profile',
    element: <AdminProfile />
  },
  {
    id: 13,
    path: '/admin-dashboard/draftday',
    element: <DraftDay />
  }
];
const leaderRoutes = [
  {
    id: 1,
    path: '/leader',
    element: <LeaderHome />
  },
  {
    id: 2,
    path: '/leader/meetings',
    element: <StartMeeting />
  },
  {
    id: 3,
    path: '/leader/reading-plans',
    element: <ReadingPlan />
  },
  {
    id: 4,
    path: '/group/:name/:id',
    element: <Home />
  },
  {
    id: 5,
    path: '/group/send-email/:name/:id',
    element: <GroupEmail />
  },
  {
    id: 6,
    path: '/edit-group/:id',
    element: <EditGroup />
  },
  {
    id: 7,
    path: '/assessment/:level/:groupId/:userId',
    element: <Assessment />
  },
  {
    id: 8,
    path: '/create-group',
    element: <CreateGroup />
  },
  {
    id: 9,
    path: '*',
    element: <Navigate to="/leader" />
  },
  {
    id: 10,
    path: '/group/attendance/:name/:id/:meetingId',
    element: <Attendance />
  }
];
const discipleRoutes = [
  {
    id: 1,
    path: '/disciple',
    element: <DiscipleHome />
  },
  {
    id: 2,
    path: '/disciple/email-leader',
    element: <EmailLeader />
  },
  {
    id: 3,
    path: '*',
    element: <Navigate to="/disciple" />
  },
  {
    id: 4,
    path: '/disciple/mark-attendance',
    element: <MarkAttendance />
  }
];
const discipleLeaderRoutes = [
  {
    id: 1,
    path: '/journey',
    element: <Journey />
  },
  {
    id: 2,
    path: '*',
    element: <Navigate to="/disciple" />
  }
];
const discipleLeaderAdminRoutes = [
  {
    id: 1,
    path: '/edit-profile',
    element: <EditProfile />
  },
  {
    id: 2,
    path: '/contact-us',
    element: <ContactUs />
  },
  {
    id: 3,
    path: '*',
    element: <Navigate to="/disciple" />
  },
  {
    id: 4,
    path: '/change-password',
    element: <ChangePassword />
  },
  {
    id: 4,
    path: '/change-password',
    element: <ChangePassword />
  }
];

const ProjectRoutes = () => {
  return (
    <MainContainer>
      <Routes>
        {/* Auth routes */}
        <Route element={<PublicRoutes />}>
          {publicRoutes.map(route => (
            <Route key={route.id} path={route.path} element={route.element} />
          ))}
        </Route>
        {/* SUPERADMIN ROUTES */}
        <Route element={<PrivateRoutes roles={['SUPERADMIN']} />}>
          {superadminRoutes.map(route => (
            <Route key={route.id} path={route.path} element={route.element} />
          ))}
        </Route>
        {/* ADMIN ROUTES */}
        <Route element={<PrivateRoutes roles={['ADMIN']} />}>
          {adminRoutes.map(route => (
            <Route key={route.id} path={route.path} element={route.element} />
          ))}
        </Route>
        {/* LEADER ROUTES */}
        <Route element={<PrivateRoutes roles={['LEADER']} />}>
          {leaderRoutes.map(route => (
            <Route key={route.id} path={route.path} element={route.element} />
          ))}
        </Route>
        {/* DISCIPLE ROUTES */}
        <Route element={<PrivateRoutes roles={['DISCIPLE']} />}>
          {discipleRoutes.map(route => (
            <Route key={route.id} path={route.path} element={route.element} />
          ))}
        </Route>
        {/* MERGED DISCIPLE AND LEADER ROUTES */}
        <Route element={<PrivateRoutes roles={['DISCIPLE', 'LEADER']} />}>
          {discipleLeaderRoutes.map(route => (
            <Route key={route.id} path={route.path} element={route.element} />
          ))}
        </Route>
        {/* MERGED DISCIPLE, ADMIN and LEADER routes */}
        <Route element={<PrivateRoutes roles={['DISCIPLE', 'ADMIN', 'LEADER']} />}>
          {discipleLeaderAdminRoutes.map(route => (
            <Route key={route.id} path={route.path} element={route.element} />
          ))}
        </Route>
      </Routes>
    </MainContainer>
  );
};

export default ProjectRoutes;
