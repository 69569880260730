import React from 'react';
import useTranslations from '../hooks/useTranslation';
import useAuthStore from '../store/authStore';

const LanguageSwitcher = () => {
  const {switchLanguage} = useTranslations();
  const {selectedOrganization, updateOrgDefaultLanguage} = useAuthStore(state => state);
  const language = selectedOrganization?.default_language || 1;
  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    width: '100%',
    padding: '4px 0',
    marginBottom: "10px"
  };

  const buttonStyle = {
    borderRadius: '8px',
    border: '1px solid transparent',
    padding: '0.5em 0.8em',
    fontSize: '0.8em',
    fontWeight: 500,
    fontFamily: 'inherit',
    color: '#ccc',
    backgroundColor: '#000000',
    cursor: 'pointer',
    transition: 'border-color 0.25s, background-color 0.25s'
  };

  const selectedButtonStyle = {
    ...buttonStyle,
    color: '#fff',
    backgroundColor: '#9e0001',
    borderColor: '#fd4958'
  };

  return (
    <div style={containerStyle}>
      <button
        type="button"
        onClick={() => {
          switchLanguage(1);
          updateOrgDefaultLanguage({default_language: 1});
        }}
        style={language == 1 ? selectedButtonStyle : buttonStyle}
      >
        English
      </button>
      <button
        type="button"
        onClick={() => {
          switchLanguage(2);
          updateOrgDefaultLanguage({default_language: 2});
        }}
        style={language == 2 ? selectedButtonStyle : buttonStyle}
      >
        {/* Español */}
        Spanish
      </button>
      {/* change later */}
      {/* <button
        type="button"
        onClick={() => {
          switchLanguage(3);
          updateOrgDefaultLanguage({default_language: 3});
        }}
        style={language == 3 ? selectedButtonStyle : buttonStyle}
      >
        हिंदी
        Urdu
      </button> */}
    </div>
  );
};

export default LanguageSwitcher;
