import React, {useState} from 'react';
import {Box, Button, IconButton, List, ListItemButton, ListItemText, Typography, Menu, MenuItem, useTheme, FormControl, RadioGroup, FormControlLabel, Radio, Checkbox} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {deleteDiscipleApi, updateGroupApi} from '../../../api';
import Modal from '../../../components/common/Modal';
import Loader from '../../../components/common/Loader';
import useTranslations from '../../../hooks/useTranslation';
import AddIcon from '@mui/icons-material/Add';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';
import ManuallyInvitedModal from '../../../components/common/ManuallyInvitedModal';
import IconContainer from '../../../components/common/IconContainer';
import {deletesIcon} from '../../../utils/svg';
import PersonRemoveAlt1OutlinedIcon from '@mui/icons-material/PersonRemoveAlt1Outlined';

const ItemList = ({isCompleted, isInvited, items, handleClick, selectedItem, height, title, deleteIcon = false, reloadData, loader, marginBottom, moreVert = false, isAddingDisciple = false, handleAddClick, showDeleteIcon = false, openActivateModal, width = '100%', isEmailIcon ,selectedGroup = null, reload}) => {
  const {translate} = useTranslations();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalArchieveOpen, setIsModalArchieveOpen] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [deleteOption, setDeleteOption] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const handleOpenModal = item => {
    setToDelete(item);
    setIsModalOpen(true);
  };

  const handleMenuOpen = (e, itemId) => {
    setAnchorEl(e.currentTarget);
    setSelectedItemId(itemId);
    e.stopPropagation();
  };

  const handleMenuClose = e => {
    if (e) {
      e.stopPropagation();
    }
    setAnchorEl(null);
    setSelectedItemId(null);
    setIsModalArchieveOpen(false);
    setIsModalDeleteOpen(false);
  };

  const handleCloseModal = () => {
    setToDelete(null);
    setIsChecked(false);
    setIsModalOpen(false);
  };

  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked);
  };

  const handleGroupStatusUpdate = async (groupId, status) => {
    try {
      let payload;
      // Determine the payload based on the status
      switch (status) {
        case 'ARCHIEVED':
          payload = {isMarkGroupAsArchived: true};
          break;
        case 'DELETED':
          payload = {isMarkGroupAsDeleted: true};
          break;
        default:
          return;
      }
      const res = await updateGroupApi(groupId, payload);
      if (res.data.status === 200) {
        handleMenuClose();
        if (reloadData) {
          reloadData();
        }
      }
    } catch (error) {
    } finally {
      handleMenuClose();
    }
  };
  const handleArchiveModal = e => {
    setIsModalArchieveOpen(true);
    e.stopPropagation();
  };
  const handleDeleteModal = e => {
    setIsModalDeleteOpen(true);
    e.stopPropagation();
  };

  // Handler for Archiving the group
  const handleArchiveGroup = (e, itemId) => {
    handleGroupStatusUpdate(itemId, 'ARCHIEVED');
    e.stopPropagation();
  };

  // Handler for Deleting the group (opens confirmation modal)
  const handleDeleteGroup = (e, itemId) => {
    handleGroupStatusUpdate(itemId, 'DELETED');
    e.stopPropagation();
  };

  const handleConfirmDelete = () => {
    if (toDelete) {
      handleDelete(toDelete);
    }
  };

  const handleDelete = async id => {
    try {
      setButtonLoader(true);
      const res = await deleteDiscipleApi(id , true);
      if (res?.data?.status === 200) {
        if (reload) {
          reload();
          handleCloseModal();
        }
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };
  return (
    <Box width={width} height={height} overflow="auto" bgcolor="#FFF" borderRadius="12px" marginBottom={marginBottom}>
      {loader ? (
        <Loader color="#9e0001" />
      ) : (
        <>
          {title && (
            <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="800" textAlign="left" pl={3} pt={3}>
              {title}
            </Typography>
          )}
          {isAddingDisciple && selectedGroup?.status !== "COMPLETED" &&(
            <Box
              sx={{
                display: {md: 'flex', xs: 'none', sm: 'none'},
                ml: 2.5
              }}
              justifyContent="space-between"
              alignItems="center"
            >
              <IconButton
                onClick={handleAddClick}
                sx={{
                  height: '30px',
                  pl: '1.5px',
                  '&:hover': {
                    borderRadius: '12px',
                    height: '30px'
                  }
                }}
              >
                <AddIcon
                  sx={{
                    color: '#D0373B',
                    fontSize: '20px'
                  }}
                />{' '}
                <Typography style={{fontSize: '14.4px'}} color="black" ml={0.3}>
                  {translate('Add Disciples')}
                </Typography>
              </IconButton>
            </Box>
          )}
          <List>
            {items &&
              items.length !== 0 &&
              items.map((item, index) => (
                <ListItemButton
                  key={index}
                  selected={selectedItem === item}
                  sx={{
                    paddingLeft: '25px',
                    '&.Mui-selected': {
                      color: '#f1c5c6'
                    },
                    height: '30px',
                    '&:hover': {
                      backgroundColor: handleClick ? '#f9f9f9' : 'transparent'
                    },
                    cursor: isInvited ? 'default' : 'pointer' // Change cursor to default if disabled
                  }}
                >
                  <ListItemText
                    primary={
                      <span style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span onClick={handleClick ? () => handleClick(item) : null} style={{display: 'flex', alignItems: 'center', fontWeight: '600', width: isInvited ? 'auto' : '100%'}}>
                          {item?.name ? item.name : `${item?.first_name || ''}${item?.last_name ? ' ' + item.last_name || '' : ''}`}
                          {item?.level && <span style={{marginLeft: '8px'}}>{`(${item.level})`}</span>}
                        </span>
                        <div style={{display: 'flex', alignItems: 'center', gap: 2}}>
                          {isEmailIcon && item?.is_manually_activated == 1 && (
                            <IconButton onClick={() => setIsModalOpen2(true)} style={{marginLeft: '8px', cursor: 'pointer'}}>
                              <SpeakerNotesOffOutlinedIcon style={{color: '#000000'}} />
                            </IconButton>
                          )}
                          {isCompleted && <span style={{marginRight: '8px', color: '#000000', fontWeight: '600'}}>{item?.disciple_count}</span>}
                          {isInvited && (
                            <span
                              style={{marginRight: '8px', display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                              onClick={() => {
                                openActivateModal(item);
                              }}
                            >
                              <AddIcon sx={{color: '#d32f2f'}} /> {translate('Activate Disciple')}
                            </span>
                          )}
                          {showDeleteIcon && item?.status == 'IN-PROGRESS' && selectedGroup?.status !== "COMPLETED" && (
                            <IconButton onClick={() => handleOpenModal(item?.disciple_id)} style={{marginLeft: '8px', cursor: 'pointer'}}>
                              {/* <IconContainer icon={deletesIcon} width={20} height={20} /> */}
                              <PersonRemoveAlt1OutlinedIcon sx={{color: '#000000'}} />
                            </IconButton>
                          )}
                        </div>
                      </span>
                    }
                    primaryTypographyProps={{
                      sx: {
                        color: '#000'
                      }
                    }}
                  />
                  {moreVert && (
                    <>
                      <IconButton style={{color: '#d32f2f'}} onClick={e => handleMenuOpen(e, item.user_id || item.id)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && selectedItemId === (item.user_id || item.id)}
                        onClose={handleMenuClose}
                        sx={{
                          '& .MuiPaper-root': {
                            backgroundColor: 'white',
                            color: 'black'
                          }
                        }}
                      >
                        <MenuItem style={{backgroundColor: 'white', color: 'black'}} onClick={e => handleArchiveModal(e)}>
                          {translate('ARCHIEVE GROUP')}
                        </MenuItem>
                        <MenuItem style={{backgroundColor: 'white', color: 'black'}} onClick={e => handleDeleteModal(e)}>
                          {translate('DELETE GROUP')}
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                  {deleteIcon === 'true' && (
                    <IconButton>
                      <DeleteIcon style={{color: '#d32f2f'}} onClick={() => handleOpenModal(item.user_id || item.id)} />
                    </IconButton>
                  )}
                </ListItemButton>
              ))}
          </List>

          <Modal
            modalbgColor={'#ffffff'}
            modalTextColor={'#000000'}
            modalTitleColor={'#d32f2f'}
            open={isModalOpen}
            onClose={handleCloseModal}
            title={translate('Drop Disciple')}
            buttons={[
              {name: translate('Cancel'), onClick: handleCloseModal, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
              {
                name: translate('Drop'),
                onClick: handleConfirmDelete,
                buttonBgColor: '#d32f2f',
                buttonTextColor: '#ffffff',
                buttonHoverColor: '#b71c1c',
                disabled: !isChecked || buttonLoader,
                loading: buttonLoader
              }
            ]}
          >
            {/* <Box> */}
            <Typography sx={{color: '#000000'}}>
              {translate('Are you certain that you want to drop this disciple from the group?')}
              {/* {user?.name} */}
            </Typography>
            <FormControlLabel
              sx={{'& .MuiFormControlLabel-label': {color: '#000000', fontSize: '14.8px', fontWeight: 550}}}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  sx={{
                    color: '#000000',
                    '&.Mui-checked': {
                      color: '#000000'
                    }
                  }}
                />
              }
              label={translate('I am sure that I want to drop this disciple.')}
            />
          </Modal>
          {/* For Archieve Group */}
          <Modal modalbgColor={'#ffffff'} modalTextColor={'#000000'} modalTitleColor={'#d32f2f'} open={isModalArchieveOpen} onClose={handleMenuClose} title={translate('Confirm Archieve the Group')}>
            <Box>
              <Typography variant="body1" mb={4} color="#000000">
                {translate('Are you sure you want to archieve the group?')}
              </Typography>
              <Box display="flex" justifyContent="flex-end">
                <Button variant="contained" color="secondary" onClick={handleMenuClose} sx={{mr: 1}}>
                  {translate('Cancel')}
                </Button>
                <Button variant="contained" color="secondary" onClick={e => handleArchiveGroup(e, selectedItemId)}>
                  {translate('Archieve')}
                </Button>
              </Box>
            </Box>
          </Modal>
          <ManuallyInvitedModal bgColor="#fff" textColor="#000" titleColor="#d32f2f" setIsModalOpen={setIsModalOpen2} isModalOpen={isModalOpen2} />
          <Modal
            open={isModalDeleteOpen}
            onClose={handleMenuClose}
            title={translate('Delete Group')}
            modalbgColor={'#ffffff'}
            modalTextColor={'#000000'}
            modalTitleColor={'#d32f2f'}
            buttons={[
              {name: translate('Cancel'), onClick: handleMenuClose, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
              {
                name: translate('Delete'),
                onClick: e => handleDeleteGroup(e, selectedItemId),
                buttonBgColor: '#d32f2f',
                buttonTextColor: '#ffffff',
                buttonHoverColor: '#b71c1c',
                disabled: !deleteOption
              } // Disable Delete if no option is selected
            ]}
          >
            <Typography sx={{mb: 2, color: '#000000'}}>{translate('If you are simply deleting a group, make sure you have moved disciples to new groups before proceeding.')}</Typography>
            <Typography sx={{mb: 2, color: '#000000'}}>{translate('Are you certain that you want to delete this group? This action cannot be undone!')}</Typography>
            <FormControl component="fieldset" sx={{'& .MuiFormControlLabel-label': {color: '#000000'}}}>
              <RadioGroup
                value={deleteOption}
                sx={{
                  color: '#000000 !important',
                  '& .MuiFormControlLabel-label': {
                    color: '#000000 !important'
                  }
                }}
                onChange={e => setDeleteOption(e.target.value)}
              >
                <FormControlLabel
                  value="deleteGroup"
                  control={
                    <Radio
                      sx={{
                        color: '#000000 !important',
                        '&.Mui-checked': {
                          color: '#000000 !important'
                        }
                      }}
                    />
                  }
                  label={translate('Only delete group and group data.')}
                />
                <FormControlLabel
                  value="deleteGroupAndDisciples"
                  control={
                    <Radio
                      sx={{
                        color: '#000000 !important',
                        '&.Mui-checked': {
                          color: '#000000 !important'
                        }
                      }}
                    />
                  }
                  label={translate('Delete group and all disciples from database.')}
                />
              </RadioGroup>
            </FormControl>
          </Modal>
        </>
      )}
    </Box>
  );
};

export default ItemList;
