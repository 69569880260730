import React, {useEffect, useState} from 'react';
import Sidebar from './Sidebar';
import DashboardHeader from './DashboardHeader';
import {Box} from '@mui/system';
import {getAdminOrgDisciplesApi, getAllDisciples} from '../../../api';
import useAuthStore from '../../../store/authStore';
import useAdminStore from '../../../store/adminStore';

const AdminStructure = ({children, handleAddClick, isSearch, searchHandler, heading, isAddingUser, isAddingGroup, isAddingDisciple, isAddingOrganisation, isAddingDraftDay}) => {
  const {user, selectedOrganization} = useAuthStore(state => state);
  const {totalDisciples, setTotalDisciples} = useAdminStore(state => state);

  const getDiscipleDataHandler = async () => {
    try {
      let res;
      if (user.user_role === 'SUPERADMIN') {
        res = await getAllDisciples();
        if (res?.data?.status === 200) {
          setTotalDisciples(res?.data?.data?.disciples);
        }
      } else {
        res = await getAdminOrgDisciplesApi(selectedOrganization.id, 'disciple');
        if (res?.data?.status === 200) {
          setTotalDisciples(res?.data?.data?.length);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDiscipleDataHandler();
  }, [selectedOrganization]);

  return (
    <Box display={'flex'}>
      <Sidebar totalDisciples={totalDisciples || 0} />
      <Box height={'auto'} minHeight="100vh" flexGrow={1} sx={{width: 'calc(100vw - 300px)'}}>
        <DashboardHeader user={user} heading={heading} isAddingUser={isAddingUser} handleAddClick={handleAddClick} isSearch={isSearch} searchHandler={searchHandler} isAddingGroup={isAddingGroup} isAddingDisciple={isAddingDisciple} isAddingOrganisation={isAddingOrganisation} isAddingDraftDay={isAddingDraftDay} />
        {children}
      </Box>
    </Box>
  );
};

export default AdminStructure;
