import React, {useEffect, useState} from 'react';
import {Typography, IconButton, ListItem, Tooltip, Menu, MenuItem, useTheme} from '@mui/material';
import {Box} from '@mui/system';
import CheckBoxInput from '../components/common/CheckBoxInput';
import {Paper} from '@mui/material';
import UserContainer from '../components/common/UserContainer';
import {Link, useNavigate} from 'react-router-dom';
import useAuthStore from '../store/authStore';
import {discipleReadingApi, getUsersDetails, downloadBibleReadingPlan, markChapterReadApi, getMeetings, getBadges} from '../api/index';
import moment from 'moment';
import {FILE_PATH_PROFILE_URL} from '../constants';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {capitalizeWords} from '../utils';
import LaunchIcon from '@mui/icons-material/Launch';
import Loader from '../components/common/Loader';
import DownloadIcon from '@mui/icons-material/Download';
import winnerOne from '../images/winner-one.png';
import winnerTwo from '../images/winner-two.png';
import winnerThree from '../images/winner-three.png';
import goodJobOne from '../images/good-job-one.png';
import goodJobTwo from '../images/good-job-two.png';
import goodJobThree from '../images/good-job-three.png';
import HelpOutline from '@mui/icons-material/HelpOutline';
import Modal from '../components/common/Modal';
import ModalHeader from '../components/common/ModalHeader';
import useTranslations from '../hooks/useTranslation';
import MarkAttendance from './MarkAttendance';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';

const Disciple = () => {
  const theme = useTheme();
  const iconColor = theme.palette.icon.primary;
  const {translate} = useTranslations();
  const {user} = useAuthStore(state => state);
  const [bibleData, setBibleData] = useState([]);
  const [bibleReadingData, setBibleReadingData] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [groupId, setGroupId] = useState('');
  const [meeting, setMeeting] = useState();
  const [weeklyReadingLoader, setWeeklyReadingLoader] = useState(false);
  const [lastDate, setLastDate] = useState('');
  const [lastDateForReading, setLastDateForReading] = useState('');
  const [nextMeetingDate, setNextMeetingDate] = useState(null);
  const [prevDate, setPrevDate] = useState(null);
  const [isDropping, setIsDropping] = useState(true);
  const [badgeData, setBadgeData] = useState(null);
  const [badgeLoader, setBadgeLoader] = useState(false);
  const [readMarked, setReadMarked] = useState([]);
  const [outsideMarked, setOutsideMarked] = useState([]);
  const [fetchGroupId, setfetchGroupId] = useState(null);
  const [fetchGroupDiscipleId, setfetchGroupDiscipleId] = useState(null);
  const [allBadgesStarted, setAllBadgesStarted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [meetingId, setMeetingId] = useState('');
  const [currentMeetingDate, setCurrentMeetingForAttendance] = useState(new Date());
  const [isSkipWeek, setIsSkipWeek] = useState(true);
  const [meetingTime, setMeetingTime] = useState();
  const [meetingLoader, setMeetingLoader] = useState(false);
  const getSilverBadgeImage = silverBadge => {
    switch (silverBadge) {
      case 'None':
        return null; // No badge for streak <= 0
      case 'Good_Job_1_Star':
        return goodJobOne;
      case 'Good_Job_2_Stars':
        return goodJobTwo;
      case 'Good_Job_3_Stars':
        return goodJobThree;
      case 'Winner_1_Star':
        return winnerOne;
      case 'Winner_2_Stars':
        return winnerTwo;
      case 'Winner_3_Stars':
        return winnerThree;
      default:
        return null;
    }
  };
  const getGoldBadgeImage = goldBadge => {
    switch (goldBadge) {
      case 'None':
        return null;
      case 'Good_Job_1_Star':
        return goodJobOne;
      case 'Good_Job_2_Stars':
        return goodJobTwo;
      case 'Good_Job_3_Stars':
        return goodJobThree;
      case 'Winner_1_Star':
        return winnerOne;
      case 'Winner_2_Stars':
        return winnerTwo;
      case 'Winner_3_Stars':
        return winnerThree;
      default:
        return null;
    }
  };
  const getPlatinumBadgeImage = platinumBadge => {
    switch (platinumBadge) {
      case 'None':
        return null;
      case 'Good_Job_1_Star':
        return goodJobOne;
      case 'Good_Job_2_Stars':
        return goodJobTwo;
      case 'Good_Job_3_Stars':
        return goodJobThree;
      case 'Winner_1_Star':
        return winnerOne;
      case 'Winner_2_Stars':
        return winnerTwo;
      case 'Winner_3_Stars':
        return winnerThree;
      default:
        return null;
    }
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = type => {
    setAnchorEl(null);
    if (type) {
      downloadPlan(type);
    }
  };
  const getBibleReadingData = async (weekStartDate, type) => {
    try {
      setWeeklyReadingLoader(true);
      const response = await discipleReadingApi(weekStartDate, type);
      if (response.data?.status === 200) {
        const data = response?.data;
        if (data?.data?.bible_reading.length > 0) {
          setIsSkipWeek(false);
        } else {
          setIsSkipWeek(true);
        }
        setMeetingId(data?.data?.group_meetings?.group_weekly_meeting_id);
        setBibleReadingData(data?.data);
        setBibleData(prevBibleData => [...prevBibleData, data?.data?.bible_reading]);
        setNextMeetingDate(data?.data?.group_meetings?.next_meeting_date);
        setLastDateForReading(moment(data?.data?.group_meetings?.last_week_meeting_date).format('YYYY-MM-DD'));
        setLastDate(moment(data?.data?.group_meetings?.next_week_meeting_date));
        setPrevDate(data?.data?.group_meetings?.previous_week_meeting_date);
        setfetchGroupId(data?.data?.group_meetings?.group_id);
        setfetchGroupDiscipleId(data?.data?.discipleId);
        setCurrentMeetingForAttendance(data?.data?.bible_reading[0].date);
        setMeetingTime(data?.data?.group_meetings?.next_week_meeting_date);
      }
      setWeeklyReadingLoader(false);
    } catch (error) {
      setWeeklyReadingLoader(false);
    }
  };
  const getBadgeData = async () => {
    try {
      const group_disciples_id = fetchGroupDiscipleId;
      const group_id = fetchGroupId;
      const response = await getBadges(group_disciples_id, group_id);
      if (response.data?.status === 200) {
        const {silverCount, goldCount, platinumCount, totalMeetingWeeks, silverBadge, goldBadge, platinumBadge, totalMeetingAttended} = response.data.data;
        setBadgeData({silverCount, goldCount, platinumCount, totalMeetingWeeks, silverBadge, goldBadge, platinumBadge, totalMeetingAttended});
        const allBadgesStarted = silverBadge == 'None' && goldBadge == 'None' && platinumBadge == 'None';
        if (allBadgesStarted) {
          setAllBadgesStarted(true);
        }
      }
    } catch (error) {
    } finally {
      setBadgeLoader(false);
    }
  };
  const getUserHandler = async () => {
    try {
      setLoading(true);
      setBadgeLoader(true);
      const currentUserData = await getUsersDetails(user?.id);
      const response = currentUserData?.data;
      if (response?.status === 200) {
        setBibleData([]);
        setProfileData(prev => ({
          ...prev,
          id: response?.data?.id,
          name: `${response?.data?.first_name} ${response?.data?.last_name === 'undefined' ? '' : response?.data?.last_name || ''}`,
          avatar: response?.data?.avatar !== null ? `${FILE_PATH_PROFILE_URL}/${response?.data?.avatar}` : ''
        }));
        if (response?.data?.disciple_status === 'DROPPING') {
          setIsDropping(true);
        } else {
          setIsDropping(false);
          getGroupMeetingHandler();
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handlePrevious = () => {
    setBibleData([]);
    getBibleReadingData(moment(prevDate).format('YYYY-MM-DDTHH:mm:ss'), 'bible');
  };
  const handleNext = () => {
    setBibleData([]);
    getBibleReadingData(moment(lastDate).add(0, 'day').format('YYYY-MM-DDTHH:mm:ss'), 'bible');
  };
  const downloadPlan = async type => {
    try {
      const response = await downloadBibleReadingPlan(type);
      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = blobUrl;
      link.setAttribute('download', `bible_reading_plan.${type}`);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(link);
    } catch (error) {}
  };
  const markReadHandler = async (id, read, type) => {
    let data;
    if (type === 'bible') {
      data = {
        groupId: groupId,
        chapterId: id,
        read: read
      };
    } else {
      data = {
        book_id: id,
        week_no: bibleReadingData?.week_no,
        read: read,
        groupId: groupId
      };
    }
    try {
      const res = await markChapterReadApi(data, type);
      if (res.data.status === 200) {
        if (type === 'bible') {
          setReadMarked(prev => [...prev, id]);
        } else {
          setOutsideMarked(prev => [...prev, id]);
        }
      }
    } catch (error) {}
  };
  const getGroupMeetingHandler = async () => {
    try {
      setMeetingLoader(true);
      const res = await getMeetings('', user.id);
      if (res.data.status === 200) {
        const data = res.data.data;
        setMeeting(data);
        setGroupId(data?.group_id);
        setProfileData(prev => {
          return {
            ...prev,
            groupLevel: data.level
          };
        });
      } else if (res.data.status === 204) {
        setIsDropping(true);
      }
      setMeetingLoader(false);
    } catch (error) {
      setMeetingLoader(false);
    }
  };
  function disableNextWeek() {
    if (!lastDate._isValid) {
      return true;
    }
    if (meeting.level === 'D1') {
      return moment(new Date(lastDate)).startOf('day').subtract('days', 7).format('YYYY-MM-DD') === lastDateForReading;
    } else {
      return moment(new Date(lastDate)).format('YYYY-MM-DD') === lastDateForReading;
    }
  }
  const getBibleHeading = () => {
    let currentDate = new Date();
    currentDate = currentDate.setHours(0, 0, 0, 0);
    currentDate = moment(currentDate);
    let currentMeetDate = new Date(moment(currentMeetingDate, 'MMMM Do YYYY'));
    currentMeetDate = currentMeetDate.setHours(0, 0, 0, 0);
    let previousMeetingDate = new Date(moment(prevDate, 'YYYY-MM-DD HH:mm:ss'));
    previousMeetingDate = previousMeetingDate.setHours(0, 0, 0, 0);
    let nextMeetDate = new Date(moment(nextMeetingDate, 'YYYY-MM-DD HH:mm:ss'));
    nextMeetDate = nextMeetDate.setHours(0, 0, 0, 0);
    const currentMeetingStartDate = moment(currentMeetDate);
    const nextWeekStartDate = moment(nextMeetDate);
    if (currentDate.isSame(currentMeetingStartDate) || (currentMeetingStartDate.isBefore(currentDate) && nextWeekStartDate.isAfter(currentDate))) {
      return translate('This Week');
    } else if (moment(new Date()).isBefore(currentMeetDate)) {
      return translate(`Reading for ${currentMeetingStartDate.format('MMMM Do')}`);
    } else {
      return translate(`${currentMeetingStartDate.format('MMMM Do')} Reading`);
    }
  };
  useEffect(() => {
    getUserHandler();
  }, []);
  useEffect(() => {
    if (meeting && !isDropping) {
      let today = moment(new Date()).format('YYYY-MM-DD');
      setLastDate(today);
      if (today) {
        getBibleReadingData(today, 'bible');
      }
    }
  }, [meeting, isDropping]);
  useEffect(() => {
    if (fetchGroupId && fetchGroupDiscipleId) {
      getBadgeData();
    }
  }, [fetchGroupId, fetchGroupDiscipleId]);

  return (
    <UserContainer
      addText={translate('Email Leader')}
      icon={<MailOutlinedIcon sx={{fontSize: '1.8rem'}} />}
      onClick={() => {
        navigate('/disciple/email-leader');
      }}
      isShow={!isDropping}
      isShow2={false}
      headingText={profileData?.name}
      filePath={profileData?.avatar}
      headerText={nextMeetingDate && `${translate('Your next meeting is')} ${moment(nextMeetingDate, 'DD-MM-YYYY').format('dddd, MMMM Do')}`}
      showCheckbox={true}
      isDisciple={true}
      fetchGroupDiscipleId={fetchGroupDiscipleId}
      meetingId={meetingId}
      currentMeetingDate={currentMeetingDate}
      previousMeetingDate={prevDate}
      nextMeetingDate={nextMeetingDate}
      isSkipWeek={isSkipWeek}
    >
      {loading || weeklyReadingLoader || meetingLoader ? (
        <Loader />
      ) : isDropping ? (
        <Box display="flex" justifyContent="center" mt={10}>
          <Typography fontSize={18}>{translate('You aren’t currently assigned to a group, contact your administrator')}</Typography>
        </Box>
      ) : bibleReadingData?.no_reading_plan ? (
        <Box display="flex" justifyContent="center" mt={10}>
          <Typography fontSize={18}>{translate('No Reading Plan set please contact leader')}</Typography>
        </Box>
      ) : (
        <Box>
          {/* your stats */}
          <Box display="flex" flexDirection="column" alignItems="start" mx={2}>
            <Typography variant="h4" fontWeight={'bold'}>
              {translate('Your Stats')}
              <IconButton
                mx={2}
                mb={1}
                style={{
                  color: iconColor
                }}
                onClick={() => setModalOpen(true)}
              >
                <HelpOutline style={{color: 'white', fontSize: '1.8rem', marginTop: '-5px'}} />
              </IconButton>{' '}
            </Typography>
            <hr style={{width: '100%'}} />
          </Box>

          {badgeLoader ? (
            <Loader />
          ) : (
            <Box mt={2} mx={2} display="flex" flexDirection="column" alignItems="start">
              {allBadgesStarted ? (
                <Typography variant="h6">{translate('No Badges Earned!')}</Typography>
              ) : (
                badgeData && (
                  <>
                    {badgeData.platinumBadge != 'None' && (
                      <ListItem disablePadding>
                        <IconButton>{badgeData.platinumBadge && <img src={getPlatinumBadgeImage(badgeData?.platinumBadge)} alt="" style={{marginRight: '10px', height: '50px', width: '100px'}} />}</IconButton>
                        <Box display="flex" flexDirection="column" alignItems="start">
                          <Typography style={{fontSize: '18px'}}>
                            {translate('Engagement Level - ')} {badgeData?.platinumCount}%
                          </Typography>
                          <Typography style={{fontSize: '12px'}}>{translate('Platinum Level')}</Typography>
                        </Box>
                      </ListItem>
                    )}
                    {badgeData.goldBadge != 'None' && (
                      <ListItem disablePadding>
                        <IconButton>{badgeData.goldBadge && <img src={getGoldBadgeImage(badgeData?.goldBadge)} alt="Gold Badge" style={{marginRight: '10px', height: '50px', width: '100px'}} />}</IconButton>
                        <Box display="flex" flexDirection="column" alignItems="start">
                          <Typography style={{fontSize: '18px'}}>
                            {translate("You've Read")} {badgeData?.totalMeetingAttended} {translate('of')} {badgeData?.totalMeetingWeeks} {translate('Weeks')}
                          </Typography>
                          <Typography style={{fontSize: '12px'}}>{translate('Gold Level')}</Typography>
                        </Box>
                      </ListItem>
                    )}
                    {badgeData.silverBadge != 'None' && (
                      <ListItem disablePadding>
                        <IconButton>{badgeData.silverBadge && <img src={getSilverBadgeImage(badgeData?.silverBadge)} alt="Silver Badge" style={{marginRight: '10px', height: '50px', width: '100px'}} />}</IconButton>
                        <Box display="flex" flexDirection="column" alignItems="start">
                          <Typography style={{fontSize: '18px'}}>
                            {translate('Reading Streak - ')}
                            {badgeData?.silverCount} {translate('Weeks')}
                          </Typography>
                          <Typography style={{fontSize: '12px'}}>{translate('Silver Level')}</Typography>
                        </Box>
                      </ListItem>
                    )}
                  </>
                )
              )}
            </Box>
          )}

          <Box
            display="flex"
            flexDirection="row" // Change this to "row"
            justifyContent="space-between"
            mx={1}
          >
            <Box display="flex" flexDirection="column" alignItems="start" mx={1} mt={3}>
              <Typography variant="h4" textAlign="left" fontWeight={'bold'}>
                {getBibleHeading()}
              </Typography>
              <Typography variant="body2" fontSize={15} fontWeight={400} textAlign="left">
                Check It Off Daily to Earn Badges!
              </Typography>
            </Box>
            <Tooltip title={translate('Download Options')}>
              <IconButton aria-label="download" onClick={handleClick}>
                <DownloadIcon style={{color: 'white', margin: '21px 0 0 0'}} />
              </IconButton>
            </Tooltip>
            <Menu id="download-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => handleClose(null)}>
              <MenuItem
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: 'black',
                    color: 'white'
                  }
                }}
                onClick={() => handleClose('pdf')}
              >
                {translate('PDF')}
              </MenuItem>
              <MenuItem
                sx={{
                  backgroundColor: 'white',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: 'black',
                    color: 'white'
                  }
                }}
                onClick={() => handleClose('xlsx')}
              >
                {translate('XLSX')}
              </MenuItem>
            </Menu>
          </Box>
          <hr style={{width: '93.5%', margin: '0 15px'}} />
          <Box mx={2} mt={1} color="white" minHeight="275px">
            {weeklyReadingLoader ? (
              <Loader />
            ) : (
              bibleData &&
              bibleData?.map((weekData, weekIndex) => (
                <React.Fragment key={weekIndex}>
                  {weekData.length === 0 ? (
                    <Box minHeight="275px" display="flex" alignItems="center" justifyContent="center">
                      <Typography>{translate('No Bible Reading for this week.')}</Typography>
                    </Box>
                  ) : (
                    weekData.map((subItem, index) => (
                      <Box display="flex" alignItems="center" key={index} mb={index === weekData.length - 1 ? 0 : 1}>
                        <CheckBoxInput mb="0px" defaultValue={subItem.read} disabled={subItem.read || readMarked.includes(subItem?.id)} type="checkbox" id={`week-${weekIndex}-${index}`} onChange={value => markReadHandler(subItem?.id, value, 'bible')} />
                        <label style={{alignContent: 'center', textAlign: 'left'}} htmlFor={`week-${weekIndex}-${index}`}>
                          {moment(subItem.date, 'MMMM Do YYYY').format('dddd, MMMM Do')} &bull; {subItem.chapters}
                        </label>
                      </Box>
                    ))
                  )}
                  <hr style={{width: '100%', marginTop: '10px'}} />
                  <MarkAttendance fetchGroupDiscipleId={fetchGroupDiscipleId} meetingId={meetingId} currentMeetingDate={currentMeetingDate} previousMeetingDate={prevDate} nextMeetingDate={lastDate} isSkipWeek={isSkipWeek} meetingTime={meetingTime} />
                  {bibleReadingData?.outside_reading?.current_book?.map(book => (
                    <Box key={book} display="flex" alignItems="center">
                      <CheckBoxInput mb="0px" defaultValue={book?.read} disabled={book.read} type="checkbox" id={book?.book_id} onChange={value => markReadHandler(book?.book_id, value, 'outside')} />
                      <label style={{alignContent: 'center', textAlign: 'left'}} htmlFor={book?.book_id}>
                        I read {book?.book_name?.split(";")[0]} this week.
                      </label>
                    </Box>
                  ))}
                  <Box my={2} display="flex" gap={2} justifyContent="center">
                    <IconButton
                      onClick={handlePrevious}
                      style={{
                        fontSize: '14px',
                        color: prevDate === null ? 'lightgray' : 'white'
                      }}
                      disabled={prevDate === null}
                    >
                      <ArrowBackIosIcon /> {translate('Previous Week')}
                    </IconButton>
                    <IconButton style={{fontSize: '14px', color: disableNextWeek() ? 'lightgray' : 'white'}} onClick={handleNext} disabled={disableNextWeek()}>
                      {translate('Next Week')} <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                </React.Fragment>
              ))
            )}
          </Box>
          {bibleReadingData?.outside_reading?.next_book && (
            <>
              <Box display="flex" flexDirection="column" alignItems="start" mx={2} mt={3}>
                <Typography variant="h4" fontWeight={600}>
                  {translate("It's Time to Order!")}
                </Typography>
                <hr style={{width: '100%'}} />
              </Box>
              {bibleReadingData?.outside_reading?.next_book?.split('|')?.map(book => {
                return (
                  <Box mt={1} mx={2} mb={1} display="flex" alignItems="start">
                    <Typography variant="h2" color="white" fontSize={20} fontWeight={400} display="flex" marginRight={1} gap={1}>
                      {book?.split(";")[0]}
                    </Typography>
                    {
                      <Link to={book?.split(";")[1]} target="_blank">
                        <LaunchIcon sx={{color: 'white'}} />
                      </Link>
                    }
                  </Box>
                );
              })}
            </>
          )}
          {/* Modal implementation */}
          <Modal open={modalOpen} setOpen={setModalOpen} buttons={[{name: translate('Close'), onClick: () => setModalOpen(false)}]} style={{height: '100%'}} modalbgColor="white">
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} textAlign={'justify'} p={1}>
              <ModalHeader title={`${translate('Two Badges')} <br/> ${translate('Three Stars Each')}`} />
              <Typography variant="h7" fontWeight="bold" mt={1} color="#000">
                {translate('PLATINUM LEVEL - ENGAGEMENT')}
              </Typography>
              <Typography color="#000">{translate('This is the most difficult level. Being there and being prepared will allow you to get the most out of discipleship. Your engagement score is your Overall Reading Percentage times Total Meetings Attended divided by Total Meetings.')}</Typography>
              <Box display={'flex'} gap={2} mb={1}>
                <Box>
                  <img src={winnerOne} alt="img" />
                  <Typography mt={1} color="#000">
                    {translate('ONE STAR = 95%')}
                  </Typography>
                  <Typography color="#000">{translate('TWO STAR = 97%')}</Typography>
                  <Typography color="#000">{translate('THREE STAR = 100%')}</Typography>
                </Box>
                <Box>
                  <img src={goodJobOne} alt="img" />
                  <Typography mt={1} color="#000">
                    {translate('ONE STAR = 50%')}
                  </Typography>
                  <Typography color="#000">{translate('TWO STAR = 90%')}</Typography>
                  <Typography color="#000">{translate('THREE STAR = 93%')}</Typography>
                </Box>
              </Box>
              <Typography variant="h7" fontWeight="bold" mt={1} color="#000">
                {translate('GOLD LEVEL - OVERALL READING')}
              </Typography>
              <Typography color="#000">{translate('A Percentage of how many weeks you have read.')}</Typography>
              <Box display={'flex'} gap={2} mb={1}>
                <Box>
                  <img src={winnerOne} alt="img" />
                  <Typography mt={1} color="#000">
                    {translate('ONE STAR = 95%')}
                  </Typography>
                  <Typography color="#000">{translate('TWO STAR = 97%')}</Typography>
                  <Typography color="#000">{translate('THREE STAR = 100%')}</Typography>
                </Box>
                <Box>
                  <img src={goodJobOne} alt="img" />
                  <Typography mt={1} color="#000">
                    {translate('ONE STAR = 50%')}
                  </Typography>
                  <Typography color="#000">{translate('TWO STAR = 90%')}</Typography>
                  <Typography color="#000">{translate('THREE STAR = 93%')}</Typography>
                </Box>
              </Box>
              <Typography variant="h7" fontWeight="bold" mt={1} color="#000">
                {translate('SILVER LEVEL - READING STREAK')}
              </Typography>
              <Typography color="#000">{translate('How many weeks you have read in a row.')}</Typography>
              <Box display={'flex'} gap={2}>
                <Box>
                  <img src={winnerOne} alt="img" />
                  <Typography mt={1} color="#000">
                    {translate('ONE STAR > 20')}
                  </Typography>
                  <Typography color="#000">{translate('TWO STAR > 30')}</Typography>
                  <Typography color="#000">{translate('THREE STAR > 40')}</Typography>
                </Box>
                <Box>
                  <img src={goodJobOne} alt="img" />
                  <Typography mt={1} color="#000">
                    {translate('ONE STAR < 5')}
                  </Typography>
                  <Typography color="#000">{translate('TWO STAR > 5')}</Typography>
                  <Typography color="#000">{translate('THREE STAR > 10')}</Typography>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      )}
    </UserContainer>
  );
};

export default Disciple;
