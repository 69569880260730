import React, {useState, useEffect} from 'react';
import {Box, IconButton, Typography, Checkbox, FormControl, FormControlLabel} from '@mui/material';
import Modal from '../../../components/common/Modal';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';
import {promoteUserHandlerApi, removeUserRoleApi, updateDiscipleApi, updateGroupApi} from '../../../api';
import Loader from '../../../components/common/Loader';
import NoDataFoundMessage from '../../../components/common/NoDataFoundMessage';
import useTranslations from '../../../hooks/useTranslation';
import StyledSelectInput from '../components/StyledSelectInput';
import useAuthStore from '../../../store/authStore';
import {approvedToLeadIcon, deletesIcon, editsIcon} from '../../../utils/svg';
import IconContainer from '../../../components/common/IconContainer';
import ManuallyInvitedModal from '../../../components/common/ManuallyInvitedModal';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';

const ItemDetails = ({title, details, selectedLeader, leaders, message, height, selectedGroup, loader, level, editIcon = false, deleteIcon = false, archiveIcon = false, reloadData, setIsModalOpen, setIsEditModalOpen, setIsArchiveModalOpen, fontWeight = '500', handleGroupClick, isDisciple, approvedLevel, groupList, handleClick, showEmailIcon}) => {
  const {translate} = useTranslations();
  const [newLeaderId, setNewLeaderId] = useState(null);
  const [editLeader, setEditLeader] = useState(false);
  const [selectedLeader2, setSelectedLeader2] = useState('');
  const [selectGroup, setSelectGroup] = useState('');
  const [notify, setNotify] = useState(false);
  const [isMoveDiscipleOpen, setIsMoveDiscipleOpen] = useState(false);
  const [changeLeader, setchangeLeader] = useState(false);
  const [isPromoteOpen, setIsPromoteOpen] = useState(false);
  const [groupLevel, setGroupLevel] = useState({
    D1: false,
    D2: false,
    D3: false,
    NA: false,
  });
  const {selectedOrganization} = useAuthStore(state => state);
  const [promoteLoader, setPromoteLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [initialGroupLevel, setInitialGroupLevel] = useState(null);

  const handleLeaderCheckboxChange = event => {
    setchangeLeader(event.target.checked);
  };

  const handleSave = async () => {
    try {
      setButtonLoader(true);
      const data = {
        group_id: selectGroup,
        is_notify: notify
      };
      const res = await updateDiscipleApi(selectedLeader.disciple_id, data);
      if (res.data?.status === 200) {
        setIsMoveDiscipleOpen(false);
        reloadData();
      }
      setButtonLoader(false);
      setNotify(false);
    } catch (error) {
      setButtonLoader(false);
      setIsMoveDiscipleOpen(false);
    }
  };
 
  const fetchApprovedLevel = () => {
    const approvedLevelList = approvedLevel ? approvedLevel.split(',') : ['NA'];
    const approvedLevelSet = new Set(approvedLevelList);

    const newGroupLevel = {
      D1: !isDisciple && !approvedLevel ? false : approvedLevelSet.has('D1') || approvedLevelSet.has('D2') || approvedLevelSet.has('D3'),
      D2: !isDisciple && !approvedLevel ? false : approvedLevelSet.has('D2') || approvedLevelSet.has('D3'),
      D3: !isDisciple && !approvedLevel ? false : approvedLevelSet.has('D3'),
      NA: !isDisciple && !approvedLevel ? true : approvedLevelSet.has('NA') && approvedLevelList.length === 1
    };
    setGroupLevel(newGroupLevel);
    setInitialGroupLevel(newGroupLevel);
  };

  const resetData = () => {
    setIsPromoteOpen(false);
    setPromoteLoader(false);
    fetchApprovedLevel();
  };

  const handleMenuClose = e => {
    if (e) {
      e.stopPropagation();
    }
    setIsModalArchieveOpen(false);
    setIsMoveDiscipleOpen(false);
    resetData();
  };

  useEffect(() => {
    if (selectedGroup) {
      setNewLeaderId(selectedGroup?.leader_id || null);
      setSelectGroup(selectedGroup?.group_id || null);
    }
  }, [selectedGroup]);

  const handleReplaceLeader = async () => {
    try {
      setButtonLoader(true);
      const resp = await updateGroupApi(
        selectedGroup?.id,
        {
          replace_leader: newLeaderId,
          is_notify: changeLeader
        },
        'true'
      );
      if (resp?.data?.status == 200) {
        handleGroupClick(selectedGroup);
      }
      setButtonLoader(false);
      setEditLeader(false);
      setchangeLeader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };
  const handleEditLeaderOpenModal = id => {
    setSelectedLeader2(id);
    setNewLeaderId(id); // Store the initial leader
    setEditLeader(true);
  };
  const handleEditItemCloseModal = () => {
    handleReplaceLeader();
  };

  const handleCancelEdit = () => {
    setNewLeaderId(selectedLeader2); // Reset to the original leader
    setEditLeader(false);
    setchangeLeader(false);
  };

  const handlePromoteChange = name => {
    let updatedGroupLevel = {...groupLevel};
    if (name === 'NA') {
      updatedGroupLevel = {D1: false, D2: false, D3: false, NA: true};
    } else if (name === 'D1') {
      updatedGroupLevel = {D1: true, D2: false, D3: false, NA: false};
    } else if (name === 'D2') {
      updatedGroupLevel = {D1: true, D2: !groupLevel.D2, D3: false, NA: false};
    } else if (name === 'D3') {
      updatedGroupLevel = {D1: true, D2: true, D3: !groupLevel.D3, NA: false};
    }
    setGroupLevel(updatedGroupLevel);
  };
  const isSaveDisabled = JSON.stringify(groupLevel) === JSON.stringify(initialGroupLevel);

  const getUserById = id => {
    return leaders?.find(leader => leader.user_id == id);
  };

  const handlePromoteSave = async () => {
    try {
      setPromoteLoader(true);
      let res;
      if(groupLevel.NA) {
        res = await removeUserRoleApi(selectedLeader?.id, 2, selectedOrganization.id);
      }else {
        const data = {
          level: Object.keys(groupLevel)
            .filter(key => groupLevel[key])
            .join(','),
          organization_id: selectedOrganization.id,
          user_role: isDisciple ? 'DISCIPLE' : 'LEADER'
        };
        res = await promoteUserHandlerApi(selectedLeader.id, data);
      }
      if (res?.data.status === 200) {
        setIsPromoteOpen(false);
        reloadData();
      }
      resetData();
    } catch (error) {
      resetData();
    }
  };

  useEffect(() => {
    fetchApprovedLevel();
  }, [approvedLevel]);
  return (
    <Box
      p={3}
      overflow="scroll"
      bgcolor="#FFF"
      borderRadius={4}
      display="flex"
      flexDirection="column"
      gap={0}
      textAlign="left"
      sx={{
        height: {
          md: height,
          xs: 'auto',
          sm: 'auto'
        }
      }}
    >
      {loader ? (
        <Loader color="#9e0001" />
      ) : (
        <>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="800">
                {title}
              </Typography>
              {level && (
                <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="800" style={{marginLeft: '8px'}}>
                  {`(${level})`}
                </Typography>
              )}
            </Box>
            <Box display="flex" alignItems="center">
              {showEmailIcon && selectedLeader?.is_manually_activated == 1 && (
                <IconButton onClick={() => setIsModalOpen2(true)} style={{marginLeft: '8px', cursor: 'pointer'}}>
                  <SpeakerNotesOffOutlinedIcon style={{color: '#000000'}} />
                </IconButton>
              )}
              {editIcon && selectedGroup?.status !== "COMPLETED" && (
                <IconButton
                  onClick={() => {
                    setIsEditModalOpen(true);
                  }}
                >
                  <IconContainer icon={editsIcon} width={20} height={20} />
                </IconButton>
              )}
              {deleteIcon && (
                <IconButton
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                >
                  <IconContainer icon={deletesIcon} width={20} height={20} />
                </IconButton>
              )}
              {archiveIcon && selectedGroup?.status === "COMPLETED" && (
                <IconButton
                  onClick={() => {
                    setIsArchiveModalOpen(true);
                  }}
                >
                  <ArchiveOutlinedIcon sx={{ color: '#000000' }} />
                  {/* <IconContainer icon={ArchiveOutlinedIcon} width={20} height={20} /> */}
                </IconButton>
              )}
            </Box>
          </Box>
          {details.length === 0 ? (
            <NoDataFoundMessage mt={5} message={translate('No disciples under this leader.')} />
          ) : (
            details?.map((detail, index) => (
              <div
                key={index}
                onClick={handleClick ? () => handleClick(detail) : null}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: detail?.spaceBetween ? 'space-between' : 'flex-start',
                  marginBottom: detail.mb ?? '0px',
                  cursor: handleClick && selectedLeader ? 'pointer' : 'default', // Only pointer when selectedLeader exists
                }}
              >
                <Typography
                  variant="body1"
                  color="black"
                  fontWeight={detail.fontWeight ?? fontWeight}
                  mr={1}
                  sx={{
                    '& .nth-child(2)': {
                      color: 'red'
                    },
                    fontSize: '14px',
                    marginBottom: '0px !important'
                  }}
                >
                  {detail?.name}
                </Typography>
                {detail.detail && (
                  <Typography variant="body2" color="black" sx={{marginBottom: '0px !important', fontSize: '14px'}}>
                    {detail.detail}
                  </Typography>
                )}
                {detail?.leaders && (
                  <Typography variant="body2" color="black" sx={{marginBottom: '0px !important', fontSize: '14px'}}>
                    {detail?.leaders?.name}
                  </Typography>
                )}
                {detail?.icon && (
                  <IconButton
                    onClick={() => {
                      handleEditLeaderOpenModal(detail.leaders.id);
                    }}
                  >
                    <IconContainer icon={editsIcon} width={20} height={20} />
                  </IconButton>
                )}
                {detail?.name === translate('In Group:') && (
                  <IconButton
                    onClick={() => {
                      setIsMoveDiscipleOpen(true);
                      // handleEditLeaderOpenModal(detail.leaders.id);
                    }}
                  >
                    <IconContainer icon={editsIcon} width={20} height={20} />
                  </IconButton>
                )}
                {detail?.promote && (
                  <IconButton
                    onClick={() => {
                      if (setIsPromoteOpen) setIsPromoteOpen(true);
                      // handleEditLeaderOpenModal(detail.leaders.id);
                    }}
                  >
                    <IconContainer icon={approvedToLeadIcon} width={20} height={20} fontWeight={'bold'} color={'#000000'} />
                  </IconButton>
                )}
                {message && (
                  <Typography variant="body2" color="black" ml={1}>
                    {message}
                  </Typography>
                )}
              </div>
            ))
          )}
          <Modal
            modalbgColor="#ffffff"
            modalTextColor="#000000"
            modalTitleColor="#d32f2f"
            open={editLeader}
            onClose={handleEditItemCloseModal}
            title={translate('Replace/Assign New Leader')}
            buttons={[
              {
                name: translate('Cancel'),
                onClick: handleCancelEdit,
                buttonBgColor: '#ffffff',
                buttonTextColor: 'black',
                buttonHoverColor: '#f5f5f5',
                type: 'outlined'
              },
              {
                name: translate('Replace'),
                onClick: handleEditItemCloseModal,
                buttonBgColor: '#d32f2f',
                buttonTextColor: '#ffffff',
                buttonHoverColor: '#b71c1c',
                disabled: !newLeaderId,
                disabled: buttonLoader,
                loading: buttonLoader
              }
            ]}
          >
            <Box overflow="hidden">
              <StyledSelectInput
                value={newLeaderId || selectedLeader2} // Pre-select the current leader or new leader
                onChange={e => {
                  const selectedId = e.target.value;
                  getUserById(selectedId);
                  setNewLeaderId(selectedId);
                }}
                optionArray={leaders?.map(leader => ({
                  value: leader.id,
                  label: leader.name ? leader.name : translate('Deleted User'),
                  disabled: leader.id === selectedLeader2,
                  selected: leader.id === selectedLeader2
                }))}
              />
              <Typography variant="body1" mt={-2} color="#888888">
                {translate('Type to Search for Existing Leader')}
              </Typography>
              <FormControlLabel
                sx={{
                  '& .MuiFormControlLabel-label': {color: '#000000 !important'},
                  '& .MuiCheckbox-root': {color: '#000000 !important'},
                  mb: -1
                }}
                control={<Checkbox checked={changeLeader} onChange={handleLeaderCheckboxChange} />}
                label={translate('Send Notification to New Leader')}
              />
            </Box>
          </Modal>

          {/* modal for move disciple to new group */}
          <Modal
            open={isMoveDiscipleOpen}
            onClose={handleMenuClose}
            title={translate('Move Disciple to New Group')}
            modalbgColor="#ffffff"
            modalTextColor="#000000"
            modalTitleColor="#d32f2f"
            buttons={[
              {
                name: translate('Cancel'),
                onClick: () => {
                  setIsMoveDiscipleOpen(false);
                  setNotify(false);
                  setSelectGroup(selectedGroup?.group_id || null);
                },
                buttonBgColor: '#ffffff',
                buttonTextColor: 'black',
                buttonHoverColor: '#f5f5f5',
                type: 'outlined'
              },
              {
                name: translate('Save'),
                onClick: handleSave,
                buttonBgColor: '#d32f2f',
                buttonTextColor: '#ffffff',
                buttonHoverColor: '#b71c1c',
                disabled: !selectGroup || buttonLoader,
                loading: buttonLoader
              }
            ]}
          >
            <FormControl fullWidth sx={{mb: 2}}>
              <StyledSelectInput optionArray={groupList} labelId="group-select-label" value={selectGroup} onChange={e => setSelectGroup(e.target.value)} displayEmpty sx={{color: '#000000'}} />
            </FormControl>
            <Typography variant="body2" sx={{mt: -4, color: '#888888'}}>
              {translate('Select Existing Group')}
            </Typography>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {color: '#000000 !important'},
                '& .MuiCheckbox-root': {color: '#000000 !important'}
              }}
              control={<Checkbox checked={notify} onChange={e => setNotify(e.target.checked)} />}
              label={translate('Notify the disciple via email.')}
            />
          </Modal>
        </>
      )}

      {/* Modal for promote disciple */}
      <Modal
        open={isPromoteOpen}
        onClose={handleMenuClose}
        title={isDisciple ? translate('Approve Disciple Leader') : translate('Approve Leader')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              resetData();
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {name: translate('Save'), onClick: handlePromoteSave, buttonBgColor: '#d32f2f', buttonTextColor: '#ffffff', buttonHoverColor: '#b71c1c', disabled: isSaveDisabled || promoteLoader, loading: promoteLoader}
        ]}
      >
        <Typography sx={{mb: 1, fontSize: '16px', color: '#000000'}}>{translate('Select each year that the disciple is ready to lead.')}</Typography>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
          {['D1', 'D2', 'D3', 'NA'].map(level => (
            <FormControlLabel
              key={level}
              sx={{'& .MuiFormControlLabel-label': {color: '#000000'}, mb: 0}}
              control={
                <Checkbox
                  checked={groupLevel[level]}
                  onChange={() => handlePromoteChange(level)}
                  sx={{
                    color: '#000000',
                    '&.Mui-checked': {
                      color: '#000000'
                    },
                    py: 0.2
                  }}
                />
              }
              label={translate(level)}
            />
          ))}
        </Box>
      </Modal>
      <ManuallyInvitedModal bgColor="#fff" textColor="#000" titleColor="#d32f2f" setIsModalOpen={setIsModalOpen2} isModalOpen={isModalOpen2} />
    </Box>
  );
};

export default ItemDetails;
