import React, {useState} from 'react';
import {Box, List, ListItemButton, ListItemText, Typography, Drawer, IconButton, useTheme, ListItem} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import sdlogo from '../../../images/sdlogo.png';
import useAuthStore from '../../../store/authStore';
import useAdminStore from '../../../store/adminStore';
import useLeaderStore from '../../../store/leaderStore';
import {useNavigate} from 'react-router-dom';
import Modal from '../../../components/common/Modal';
import {deleteUser} from '../../../api';
import useTranslations from '../../../hooks/useTranslation';
import {barIcon, doublePersonIcon, exitIcon, homeIcon, multiPersonIcon, organisationIcon, settingIcon, sheetIcon, singlePersonIcon} from '../../../utils/svg';
import IconContainer from '../../../components/common/IconContainer';
import {CONSTANTS, FILE_PATH_ORGANIZATION} from '../../../constants';
import LanguageSwitcher from '../../../components/LanguageSwitcher';
import BusinessIcon from '@mui/icons-material/Business';

function Sidebar({totalDisciples, setIsDeleteModalOpen}) {
  const theme = useTheme();
  const iconColor = theme.palette.icon.secondary;
  const bgColor = theme.palette.background.primary;
  const selectBgColor = theme.palette.background.secondary;
  const textColor = theme.palette.text.primary;
  const {translate} = useTranslations();
  const [mobileOpen, setMobileOpen] = useState(false);
  const {logoutUser, user, selectedOrganization} = useAuthStore(state => state);
  const {clearAdminData} = useAdminStore(state => state);
  const {clearLeaderData} = useLeaderStore(state => state);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const pathName = window.location.pathname;

  const adminSidebarOptions = [
    {
      id: 1,
      path: '/admin-dashboard',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={homeIcon} width={20} height={20} marginRight={8} />,
      title: translate('Dashboard'),
      type: 'list-item'
    },
    {
      id: 2,
      path: '/admin-dashboard/groups',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={multiPersonIcon} width={20} height={20} marginRight={8} />,
      title: translate('Groups'),
      type: 'list-item'
    },
    {
      id: 3,
      path: '/admin-dashboard/leaders',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={doublePersonIcon} width={20} height={20} marginRight={8} />,
      title: translate('Leaders'),
      type: 'list-item'
    },
    {
      id: 4,
      path: '/admin-dashboard/disciples',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={singlePersonIcon} width={20} height={20} marginRight={8} />,
      title: translate('Disciples'),
      type: 'list-item'
    },
    // will check later: do not remove this code
    // {
    //   id: 5,
    //   path: '/admin-dashboard/admins',
    //   icon: path => <PersonIcon sx={{ mr: 1, color: path === pathName ? iconColor : '#8A8A8A' }} />,
    //   title: translate("Admins"),
    //   type: 'list-item'
    // },
    // {
    //   id: 6,
    //   path: '/admin-dashboard/promotions',
    //   icon: path => <GroupsIcon sx={{ mr: 1, color: path === pathName ? iconColor : '#8A8A8A' }} />,
    //   title: translate("Promotions"),
    //   type: 'list-item'
    // },
    {
      id: 7,
      path: '/admin-dashboard/reports',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={sheetIcon} width={20} height={20} marginRight={8} />,
      title: translate('Reports'),
      type: 'list-item'
    },
    {
      id: 8,
      type: 'seperator'
    },
    {
      id: 9,
      path: '/admin-dashboard/reading-plans',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={barIcon} width={20} height={20} marginRight={8} />,
      title: translate('Reading Plan'),
      type: 'list-item'
    },
    {
      id: 10,
      path: '/admin-dashboard/settings',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={settingIcon} width={20} height={20} marginRight={8} />,
      title: translate('Settings'),
      type: 'list-item'
    }
    // Comment for now & change later
    // {
    //   id: 11,
    //   path: '/admin-dashboard/draftday',
    //   icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={settingIcon} width={20} height={20} marginRight={8} />,
    //   title: translate('Draft Day'),
    //   type: 'list-item'
    // }
    // change later
    // {
    //   id: 11,
    //   path: '/admin-dashboard/change-password',
    //   icon: path => <PasswordIcon sx={{mr: 1, color: path === pathName ? iconColor : '#8A8A8A'}} />,
    //   title: translate('Change Password'),
    //   type: 'list-item'
    // }
  ];

  const superAdminSidebarOptions = [
    {
      id: 1,
      path: '/super-admin/dashboard',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={homeIcon} width={20} height={20} marginRight={8} />,
      title: translate('Dashboard'),
      type: 'list-item'
    },
    {
      id: 2,
      path: '/super-admin/organization',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={organisationIcon} width={20} height={20} marginRight={8} />,
      title: translate('Organizations'),
      type: 'list-item'
    },
    {
      id: 3,
      path: '/super-admin/reading-plans',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={barIcon} width={20} height={20} marginRight={8} />,
      title: translate('Reading Plan'),
      type: 'list-item'
    },
    {
      id: 4,
      path: '/super-admin/reports',
      icon: path => <IconContainer color={path === pathName ? iconColor : '#8A8A8A'} icon={sheetIcon} width={20} height={20} marginRight={8} />,
      title: translate('Reports'),
      type: 'list-item'
    }
  ];

  // Retrieve user role from localStorage
  const state = JSON.parse(localStorage.getItem('auth-storage'));
  const userRole = state?.state?.user?.user_role;

  // Dynamically assign sidebar options based on user role
  const sidebarOptions = userRole === 'ADMIN' ? adminSidebarOptions : superAdminSidebarOptions;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const currentYear = new Date().getFullYear();
  const logoutUserHandler = () => {
    logoutUser();
    clearLeaderData();
    clearAdminData();
  };

  const handleDeleteUser = async () => {
    try {
      setLoading(true);
      const res = await deleteUser(user?.id);
      if (res?.data?.status === 200) {
        setModalOpen(false);
        logoutUserHandler();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const drawerContent = (
    <Box bgcolor={bgColor} color={textColor} display="flex" flexDirection="column" px={2} overflow={'auto'} height={'100%'}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 6,
          margin: '20px 0'
        }}
      >
        <img
          src={selectedOrganization?.avatar ? `${FILE_PATH_ORGANIZATION}/${selectedOrganization.avatar}` : sdlogo}
          alt="logo"
          width={25}
          height={25}
          style={{borderRadius: '50%'}}
          onError={e => {
            e.target.onerror = null;
            e.target.src = sdlogo;
          }}
        />
        <Typography fontWeight="bold" fontSize={14}>
          {selectedOrganization?.name ?? 'Sustainable Discipleship'}
        </Typography>
      </div>
      <Box
        onClick={() => navigate(userRole === 'SUPERADMIN' ? '/super-admin/dashboard' : '/admin-dashboard')}
        borderRadius="10px"
        textAlign="center"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        py={2}
        sx={{
          background: selectBgColor,
          cursor: 'pointer'
        }}
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h3">{totalDisciples} </Typography>
        </Box>
        <Typography variant="subtitle1">{translate('Lifetime Disciples')}</Typography>
      </Box>
      <List sx={{color: iconColor, margin: '0 10px'}}>
        {sidebarOptions.map(option => {
          if (option.type === 'seperator') {
            return <hr key={option.id} style={{width: '90%', marginLeft: '6px', opacity: '50%', margin: '10px 0'}} />;
          } else {
            return (
              <ListItemButton
                key={option.id}
                className="mb-2"
                onClick={() => {
                  navigate(option.path);
                }}
                sx={{
                  height: '40px',
                  marginBottom: '10px',
                  background: pathName === option.path ? selectBgColor : 'none',
                  borderRadius: '30px'
                }}
              >
                {option.icon(option.path)}
                <ListItemText
                  primary={option.title}
                  primaryTypographyProps={{
                    style: {
                      fontSize: '13px',
                      color: pathName === option.path ? 'white' : '#8A8A8A',
                      fontWeight: 'bold'
                    }
                  }}
                />
              </ListItemButton>
            );
          }
        })}
        {/* Conditionally render "Delete My Account" */}
        {/* change later */}
        {/* {userRole === 'ADMIN' && (
          <ListItemButton
            sx={{
              height: '40px',
              borderRadius: '30px',
              marginBottom: '10px'
            }}
            onClick={() => setModalOpen(true)}
          >
            <DeleteOutline sx={{mr: 1, color: '#8A8A8A'}} />
            <ListItemText
              primary={translate('Delete My Account')}
              primaryTypographyProps={{
                style: {
                  fontSize: '13px',
                  color: '#8A8A8A',
                  fontWeight: 'bold'
                }
              }}
            />
          </ListItemButton>
        )} */}

        {/* <ListItemButton
          sx={{
            borderRadius: '30px',
            paddingLeft: '20px'
          }}
          onClick={() => logoutUserHandler()}
        >
          <IconContainer color={'#8A8A8A'} icon={exitIcon} width={20} height={20} marginRight={8} />
          <ListItemText
            primary={translate('Log Out')}
            primaryTypographyProps={{
              style: {
                fontSize: '12px',
                color: '#8A8A8A',
                fontWeight: 'bold'
              }
            }}
          />
        </ListItemButton> */}
        {/* <hr style={{width: '90%', marginLeft: '6px', opacity: '50%', margin: '10px 0'}} /> */}
      </List>
      <Box mt="auto" mb={2}>
        <LanguageSwitcher />
        <Typography textAlign="center" mb={0.5} color={textColor}>
          {translate('© {currentYear} SD: All right reserved.').replace('{currentYear}', currentYear)}
        </Typography>
        <ListItem disablePadding>
          <img width={'100%'} src="/trademark.png" alt="SD Logo" />
        </ListItem>
        <Typography mt={0.5} textAlign="center">
          {translate('Developed By:')}{' '}
          <a target="_blank" className="hyper_link" href={CONSTANTS.D2I_SITE.URL}>
            D2i Technology
          </a>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          display: {md: 'none'},
          position: 'absolute',
          top: 16,
          left: 16
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: {xs: 'block', sm: 'block', md: 'none'},
          '& .MuiDrawer-paper': {boxSizing: 'border-box', width: 250}
        }}
      >
        {drawerContent}
      </Drawer>
      <Box
        sx={{
          display: {xs: 'none', sm: 'none', md: 'block'},
          width: {md: 250}
        }}
      >
        {drawerContent}
      </Box>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        title={translate('Delete Account')}
        modalbgColor={'#ffffff'}
        modalTextColor={'#000000'}
        modalTitleColor={'#d32f2f'}
        buttons={[
          {name: translate('Close'), onClick: () => setModalOpen(false), buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {name: translate('Delete'), onClick: () => handleDeleteUser(), loading: loading, buttonBgColor: '#d32f2f', buttonTextColor: '#ffffff', buttonHoverColor: '#b71c1c'}
        ]}
      >
        <Typography sx={{color: 'black'}}>{translate('Are you sure you want to delete your account? All associated data will be removed from the application soon, and you will be logged out immediately.')}</Typography>
      </Modal>
    </>
  );
}

export default Sidebar;
