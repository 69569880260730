import {Alert, Avatar, Button, Grid, IconButton, Typography} from '@mui/material';
import {Box} from '@mui/system';
import React, {useState} from 'react';
import CheckBoxInput from '../../common/CheckBoxInput';
import HeaderContainer from '../../common/HeaderBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from 'react-router-dom';
import Loader from '../../common/Loader';
import useTranslations from '../../../hooks/useTranslation';
import SpeakerNotesOffOutlinedIcon from '@mui/icons-material/SpeakerNotesOffOutlined';
import ManuallyInvitedModal from '../../common/ManuallyInvitedModal';

const DiscipleItem = ({disciple, setEmails, emails, setError}) => {
  const {translate} = useTranslations();
  const isManuallyActivated = disciple?.is_manually_activated == 1 || disciple?.is_manually_activated == true;
  const isInvited = disciple?.user_status === "INVITED";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleChecked = email => {
    setError(null);
    setEmails(prevEmails => {
      if (prevEmails.includes(email)) {
        return prevEmails.filter(em => em !== email);
      } else {
        return [...prevEmails, email];
      }
    });
  };

  return (
    <Box py={2} px={1} display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" sx={{cursor: isManuallyActivated || isInvited ? "cursor" : "pointer", width: '100%'}}>
        <Box display="flex" alignItems="center" width={'100%'}>
          <CheckBoxInput checked={emails.includes(disciple?.email)} onChange={() => handleChecked(disciple?.email)} disabled={isManuallyActivated || isInvited} />
          <Avatar sx={{width: 56, height: 56}} alt={disciple?.first_name} src={disciple?.avatarSrc} />
          <Box textAlign="left" ml={2}>
            <Typography variant="h5" fontWeight={600} color={isManuallyActivated || isInvited ? '#8A8A8A' : 'white'}>
              {disciple?.first_name || '' + ' ' + disciple?.last_name || ''}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="h6" fontSize={14} sx={{marginRight: '4px'}} color={isManuallyActivated || isInvited ? '#8A8A8A' : 'white'}>
                {translate('Email:')}
              </Typography>
              <Typography variant="h6" fontSize={14} color={isManuallyActivated || isInvited ? '#8A8A8A' : 'white'}>
                {disciple?.email}
              </Typography>
            </Box>
            {disciple?.title && (
              <Typography variant="p" fontSize={14} color={isManuallyActivated || isInvited ? '#8A8A8A' : 'white'}>
                {disciple?.title?.replace(/,/g, ' • ')}
              </Typography>
            )}
          </Box>
        </Box>
        <Box display="flex" alignItems="end">
          {isManuallyActivated && (
            <IconButton onClick={() => setIsModalOpen(true)} style={{cursor: 'pointer'}}>
              <SpeakerNotesOffOutlinedIcon style={{color: 'white', fontSize: '2rem'}} />
            </IconButton>
          )}
        </Box>
      </Box>
      <ManuallyInvitedModal bgColor="#fff" textColor="#000" titleColor="#000" setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
    </Box>
  );
};

const GroupSelectUsers = ({loading, disciples, setEmails, emails, setIsDiscipleSelected}) => {
  const {translate} = useTranslations();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  return (
    <>
      <HeaderContainer>
        <ArrowBackIcon style={{cursor: 'pointer', color: 'white'}} onClick={() => navigate(-1)} color="white" />
      </HeaderContainer>
      {loading ? (
        <Loader />
      ) : disciples && disciples?.length > 0 ? (
        <Box>{disciples?.length && disciples.map(dis => <DiscipleItem key={dis?.id} disciple={dis} setEmails={setEmails} emails={emails} setError={setError} />)}</Box>
      ) : (
        <Box marginTop={10}>
          <Typography variant="h5" marginBottom={4}>
            {translate('Invite your first people!')}
          </Typography>
          <Typography variant="p">{translate('Once a disciple is added manually or accepts an invitation, they will be added here')}</Typography>
        </Box>
      )}
      <Grid item sx={{height: '50px'}}>
        {error && <Alert severity="error">{error}</Alert>}
      </Grid>
      <Box my={2}>
        <Button
          sx={{fontWeight: 400}}
          onClick={() => {
            emails.length > 0 ? setIsDiscipleSelected(true) : setError(translate('Select atleast one disciple!'));
          }}
          variant="contained"
          color="secondary"
        >
          {translate('Proceed')}
        </Button>
      </Box>
    </>
  );
};

export default GroupSelectUsers;
