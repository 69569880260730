import React, {useState, useEffect} from 'react';
import {Box, Typography, useMediaQuery, Checkbox, FormControlLabel, IconButton} from '@mui/material';
import ItemList from './common/ItemList';
import ItemDetails from './common/Details';
import {activateDisciple, addAddressApi, addDisciple, deleteUser, getAdminGroupsApi, getAdminOrgDisciplesApi, getDiscipleDetailsApi} from '../../api';
import AdminStructure from './common/AdminStructure';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';
import moment from 'moment';
import {CONSTANTS} from '../../constants';
import useTranslations from '../../hooks/useTranslation';
import Modal from '../../components/common/Modal';
import {Edit} from '@mui/icons-material';
import EditDisciple from './components/EditDisciple';
import AddDisciple from './components/AddDisciple';
import useAuthStore from '../../store/authStore';
import {isEmptyObjectValues, validateEmail, validatePhone} from '../../utils';
import {useLocation, useNavigate} from 'react-router-dom';
import IconContainer from '../../components/common/IconContainer';
import { editsIcon } from '../../utils/svg';

function Disciples() {
  const {translate} = useTranslations();
  const {selectedOrganization} = useAuthStore(state => state);
  const [selectedDisciple, setSelectedDisciple] = useState(null);
  const [activateDiscipleData, setActivateDiscipleData] = useState(null);
  const [adminDisciple, setAdminDisciple] = useState([]);
  const [discipleData, setDiscipleData] = useState([]);
  const [searchDisciples, setSearchDisciples] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [loader, setLoader] = useState(false);

  const [isAddDiscipleOpen, setIsAddDiscipleOpen] = useState(false);
  const [disciples, setDisciples] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [groupList, setGroupList] = useState([]);
  const [isActivateOpen, setIsActivateOpen] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const location = useLocation();
  const hashId = location.hash.slice(1) || null;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    zipcode: '',
    city: '',
    state: '',
    country: '',
    birthday: '',
    salvation_year: '',
    mobile_number: '',
    email: '',
    user_id: selectedDisciple?.id || ''
  });

  const [disData, setDisData] = useState({
    name: '',
    phone: '',
    email: ''
  });

  const resetData = () => {
    setFormData({
      zipcode: '',
      city: '',
      state: '',
      country: '',
      birthday: '',
      salvation_year: '',
      mobile_number: '',
      email: '',
      user_id: ''
    });
  };

  const openActivateModal = disciple => {
    setActivateDiscipleData(disciple);
    setIsActivateOpen(true);
  };
  const closeActivateModal = () => {
    setActivateDiscipleData(null);
    setIsActivateOpen(false);
  };
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleEditModalOpen = data => {
    setFormData(data);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setFormData({});
  };

  const handleSaveChanges = async () => {
    try {
      let requestData = formData;
      requestData['birthday'] = moment(formData.birthday).format('YYYY-MM-DD');
      setButtonLoader(true);
      const response = await addAddressApi(requestData);
      if (response.data?.status === 200) {
        handleEditModalClose();
        resetData();
        handleDiscipleClick(selectedDisciple);
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };

  const onSaveActivateDisciple = async () => {
    if (!activateDiscipleData) {
      return;
    }
    const reqData = {
      discipleId: activateDiscipleData.id
    };
    try {
      setButtonLoader(true);
      const response = await activateDisciple(reqData);
      if (response.data.status === 200) {
        getDisciples();
        closeActivateModal();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
      closeActivateModal();
    }
  };

  const handleDiscipleSave = async () => {
    const reqData = {
      first_name: disData.name.split(' ')[0] || '',
      last_name: disData.name.split(' ')[1] || '',
      email: disData.email,
      mobile_number: disData.phone,
      organization_id: selectedOrganization.id,
      send_invite: true
    };
    try {
      setButtonLoader(true);
      const res = await addDisciple(reqData);
      if (res.data?.status === 200) {
        setIsAddDiscipleOpen(false);
        setDisData({
          name: '',
          phone: '',
          email: ''
        });
        getDisciples();
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };
  const handleDelete = async () => {
    try {
      const response = await deleteUser(itemToDelete);
      if (response?.data?.status === 200) {
        setDisciples(disciples.filter(i => i.id !== itemToDelete));
        getDisciples()
      }
      handleMenuClose();
    } catch (error) {
      handleMenuClose();
    }
  };

  // Trigger delete modal with disciple data
  const openDeleteModal = discipleId => {
    setItemToDelete(discipleId); // Set the disciple to delete
    setIsModalOpen(true);
  };

  const handleCheckboxChange = event => {
    setIsChecked(event.target.checked);
  };

  const handleMenuClose = e => {
    if (e) {
      e.stopPropagation();
    }
    setIsModalOpen(false);
    setIsChecked(false);
    setItemToDelete(null);
    setIsAddDiscipleOpen(false);
    setDisData({
      name: '',
      phone: '',
      email: ''
    });
  };

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));

  const handleDiscipleClick = async disciple => {
    navigate('/admin-dashboard/disciples');
    setLoader(true);
    setSelectedDisciple(disciple);
    setFormData(prevData => ({
      ...prevData,
      user_id: disciple.id
    }));
    const resp = await getDiscipleDetailsApi(disciple?.id, 'disciple');
    if (resp.status == 200) {
      setDiscipleData(resp?.data?.data?.[0]);
      setLoader(false);
    }
    setLoader(false);
  };

  const getDisciples = async () => {
    setLoader(true);
    try {
      setPageLoading(true);
      const response = await getAdminOrgDisciplesApi(selectedOrganization.id, 'disciple');
      if (response?.data?.status == 200) {
        setAdminDisciple(response?.data?.data);
        setSearchDisciples(response?.data?.data);
      }
      setPageLoading(false);
      setLoader(false);
    } catch (error) {
      setPageLoading(false);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (adminDisciple?.length > 0) {
      let firstActiveDisciple;
      if (hashId) {
        firstActiveDisciple = adminDisciple.find(disciple => disciple.id == hashId);
        if (!firstActiveDisciple) {
          firstActiveDisciple = adminDisciple.find(disciple => disciple.status === 'ACTIVE');
        }
      } else {
        firstActiveDisciple = adminDisciple.find(disciple => disciple.status === 'ACTIVE');
      }
      if (firstActiveDisciple) {
        handleDiscipleClick(firstActiveDisciple);
      }
    }
  }, [adminDisciple]);

  const searchHandler = value => {
    if (value.length === 0) {
      setAdminDisciple(searchDisciples);
    } else {
      const filteredDisciples = searchDisciples.filter(disciple => disciple?.name?.toLowerCase().includes(value.toLowerCase()));
      setAdminDisciple(filteredDisciples);
    }
  };

  const getGroups = async () => {
    try {
      const resp = await getAdminGroupsApi(selectedOrganization.id, 'ACTIVE');
      if (resp?.status == 200) {
        setGroupList(
          resp?.data?.data?.map(group => {
            return {
              label: group?.name,
              value: group?.id,
              disabled: group?.status === 'COMPLETED',
            };
          })
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    getDisciples();
    getGroups();
  }, [selectedOrganization]);

  // Calculate the counts of each status disciple
  const activeDisciplesCount = adminDisciple ? adminDisciple.filter(disciple => disciple.status === 'ACTIVE')?.length : 0;
  const invitedDisciplesCount = adminDisciple ? adminDisciple.filter(disciple => ['COMPLETED', 'ARCHIVED', 'INVITED'].includes(disciple.status))?.length : 0;

  return (
    <div
      style={{
        backgroundImage: 'none !important',
        backgroundColor: '#f9f9f9',
        height: '100vh',
        width: '100vw',
        overflowY: isSmallScreen ? 'auto' : 'hidden',
        overflowX: 'hidden'
      }}
    >
      <AdminStructure isSearch={true} searchHandler={searchHandler} handleAddClick={() => setIsAddDiscipleOpen(true)} heading={translate('Disciples')} isAddingDisciple={true}>
        {pageLoading ? (
          <Loader color="#9e0001" />
        ) : adminDisciple?.length === 0 ? (
          <NoDataFoundMessage mt={5} message={translate('There are no disciples in this organization')} />
        ) : (
          <Box
            display="flex"
            // justifyContent="space-between"
            px={2}
            gap={2}
            sx={{
              height: {
                md: '88vh',
                xs: 'auto',
                sm: 'auto'
              },
              flexDirection: {
                md: 'row',
                sm: 'column',
                xs: 'column'
              },
              justifyContent: {
                md: 'space-between'
              }
            }}
          >
            <Box width="85%" display={'flex'} flexDirection={'column'} gap={2}>
              <ItemList items={adminDisciple.filter(disc => ['ACTIVE'].includes(disc.status))} handleClick={handleDiscipleClick} selectedItem={selectedDisciple} height={'70%'} title={`${translate('Active Disciples')} (${activeDisciplesCount})`} />
              <ItemList isInvited items={adminDisciple.filter(disc => ['COMPLETED', `ARCHIVED`, `INVITED`].includes(disc.status))} handleClick={handleDiscipleClick} selectedItem={selectedDisciple} height={'30%'} title={`${translate('Invited Disciples')} (${invitedDisciplesCount})`} openActivateModal={openActivateModal} />
            </Box>

            <Box
              width="68%"
              display="flex"
              flexDirection="column"
              // justifyContent="space-between"
              gap={2}
              sx={{
                justifyContent: {
                  md: 'space-between'
                }
              }}
            >
              {selectedDisciple && (
                <ItemDetails
                  title={selectedDisciple.name}
                  selectedLeader={{...selectedDisciple, disciple_id: discipleData?.disciple_id}}
                  deleteIcon
                  setIsModalOpen={() => openDeleteModal(selectedDisciple.id)}
                  height="42%"
                  isDisciple
                  details={[
                    {
                      name: translate('Date Finished D1:'),
                      detail: discipleData? discipleData?.d1_completion_date !== 'N/A' ? moment(discipleData?.d1_completion_date).format(CONSTANTS.DATE_FORMAT) : 'N/A' : 'N/A',
                    },
                    {
                      name: translate('Date Finished D2:'),
                      detail: discipleData? discipleData?.d2_completion_date !== 'N/A' ? moment(discipleData?.d2_completion_date).format(CONSTANTS.DATE_FORMAT) : 'N/A': 'N/A',
                    },
                    {
                      name: translate('Date Finished D3:'),
                      detail: discipleData? discipleData?.d3_completion_date !== 'N/A' ? moment(discipleData?.d3_completion_date).format(CONSTANTS.DATE_FORMAT) : 'N/A': 'N/A',
                      mb: '7px'
                    },
                    {
                      name: discipleData?.groupsLed,
                      detail: translate('Groups Led'),
                      fontWeight: '600'
                    },
                    {
                      name: discipleData?.disciplesMade,
                      detail: translate('Disciples Made'),
                      mb: '4px',
                      fontWeight: '600'
                    },
                    {
                      name: translate('In Group:'),
                      detail: discipleData?.group_name,
                      icon: '',
                      fontWeight: '600'
                    },
                    {
                      name: discipleData?.approved_level && discipleData?.approved_level !== 'NA' ? `${translate('Approved to Lead')} ${discipleData?.approved_level.split(',').pop()}` : translate('Currently not allowed to lead any group'),
                      detail: '',
                      icon: selectedDisciple?.status == 'IN-PROGRESS',
                      fontWeight: '600',
                      promote: true,
                      spaceBetween: true
                    }
                  ]}
                  loader={loader}
                  selectedGroup={{group_id: discipleData?.group_id}}
                  reloadData={() => handleDiscipleClick(selectedDisciple)}
                  approvedLevel={discipleData?.approved_level}
                  groupList={groupList}
                  showEmailIcon
                />
              )}
              {
                <>
                  {selectedDisciple && (
                    <>
                      <Box p={3} height="42%" overflowY="scroll" bgcolor="#FFF" borderRadius={5} display="flex" flexDirection="column" textAlign="left">
                        {loader ? (
                          <Loader color="#9e0001" />
                        ) : (
                          <>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <Typography variant="h6" gutterBottom color="#d32f2f" fontWeight="800">
                                {selectedDisciple?.name}'s {translate('Profile')}
                              </Typography>
                              <IconButton onClick={() => handleEditModalOpen(selectedDisciple)}>
                              <IconContainer icon={editsIcon} width={20} height={20} />
                              </IconButton>
                            </Box>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'flex-start',
                                flexDirection: 'column',
                                gap: '0.5rem'
                              }}
                            >
                              {discipleData?.title !== '' ? (
                                discipleData?.title?.split(',').map((item, index, array) =>
                                  index === 1 ? (
                                    <Typography key={index} variant="body1" color="black" fontWeight={600}>
                                      {[1, 2, 3].map(i => array[i]).join(' • ')}
                                    </Typography>
                                  ) : index === 6 ? (
                              <Typography key={index} variant="body1" color="black" fontWeight={600}>
                                      {translate(item.split("-")[0])}{" "}
                                      <span style={{ color: "#d32f2f" }}>
                                        {translate(item.split("-")[1])}
                                      </span>{" "}
                                      {translate("on the Pathway")}
                                    </Typography>

                                  ) : ![3, 4].includes(index) ? (
                                    <Typography key={index} variant="body1" color="black" fontWeight={600}>
                                      {item}
                                    </Typography>
                                  ) : null
                                )
                              ) : (
                                <NoDataFoundMessage mt={5} message={translate('No assessment data available.')} />
                              )}
                            </div>
                          </>
                        )}
                      </Box>
                    </>
                  )}
                </>
              }
            </Box>
          </Box>
        )}
      </AdminStructure>
      {/* modal for Add disciple to group */}
      <Modal
        open={isAddDiscipleOpen}
        onClose={handleMenuClose}
        title={translate('Add Disciple')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setIsAddDiscipleOpen(false);
              setDisData({
                name: '',
                email: '',
                phone: ''
              });
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: translate('Save'),
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            onClick: () => handleDiscipleSave(),
            loading: buttonLoader,
            disabled: buttonLoader || !(disData.name.trim() && validateEmail(disData.email.trim()))
          }
        ]}
      >
        <AddDisciple setFormData={setDisData} formData={disData} />
      </Modal>

      {/* MOdal for drop disciple */}
      <Modal
        open={isModalOpen}
        onClose={handleMenuClose}
        title={translate('Delete Disciple')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {name: translate('Cancel'), onClick: handleMenuClose, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {
            name: translate('Delete'),
            onClick: handleDelete,
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            disabled: !isChecked
          }
        ]}
      >
        <Typography sx={{mb: 2, color: '#000000'}}>{translate('Are you certain that you want to delete this disciple? All information will be removed from the system. This action cannot be undone!')}</Typography>
        <FormControlLabel
          sx={{'& .MuiFormControlLabel-label': {color: '#000000', fontSize: '15px', fontWeight: 550}}}
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              sx={{
                color: '#000000',
                '&.Mui-checked': {
                  color: '#000000'
                }
              }}
            />
          }
          label={translate('I am sure that I want to delete this user.')}
        />
      </Modal>

      {/* Modal for edit disciple */}
      <Modal
        open={isEditModalOpen}
        onClose={handleEditModalClose}
        title={translate('Edit Disciple')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {name: translate('Cancel'), onClick: handleEditModalClose, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {name: translate('Save'), onClick: () => handleSaveChanges(), buttonBgColor: '#d32f2f', buttonTextColor: '#ffffff', buttonHoverColor: '#b71c1c', disabled: buttonLoader || isEmptyObjectValues(formData, ['mobile_number']), loading: buttonLoader}
        ]}
      >
        {<EditDisciple formData={formData} setFormData={setFormData} selectedDiscipleId={selectedDisciple?.id} />}
      </Modal>

      {/* Modal for activate disciple*/}
      <Modal
        open={isActivateOpen}
        onClose={closeActivateModal}
        title={translate('Activate Disciple')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {name: translate('Cancel'), onClick: closeActivateModal, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {name: translate('Save'), onClick: onSaveActivateDisciple, buttonBgColor: '#d32f2f', buttonTextColor: '#ffffff', buttonHoverColor: '#b71c1c', disabled: buttonLoader, loading: buttonLoader}
        ]}
      >
        <Typography sx={{color: '#000000'}}>{translate('Are you sure you want to activate this disciple?')}</Typography>
      </Modal>
    </div>
  );
}

export default Disciples;
