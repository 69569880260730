import React from 'react';
import {Grid} from '@mui/material';
import useTranslations from '../../../hooks/useTranslation';
import AdminTextInput from './AdminTextInput';
import { validatePhone } from '../../../utils';

const AddDisciple = ({formData, setFormData, disabled = false, labels = {}}) => {
  const {translate} = useTranslations();
  const handleChange = e => {
    const {name, value} = e.target;
    if (name === 'phone') {
      if (!validatePhone(value)) {
        return;
      }
    }
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    <form style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
      <Grid item xs={12}>
        <AdminTextInput disabled={disabled} fullWidth name="name" placeholder={translate('Enter Name')} bottomLabel={labels.nameLabel || translate('Disciple Name')} value={formData.name} onChange={handleChange} variant="outlined" labelVariant="body2" isRequired />
      </Grid>
      <Grid item xs={12}>
        <AdminTextInput type="text" disabled={disabled} fullWidth name="phone" placeholder={translate('Enter Phone Number')} bottomLabel={labels.phoneLabel || translate('Phone')} value={formData.phone} onChange={handleChange} variant="outlined" labelVariant="body2" />
      </Grid>
      <Grid item xs={12}>
        <AdminTextInput disabled={disabled} fullWidth name="email" placeholder={translate('Enter Email')} bottomLabel={labels.emailLabel || translate('Email')} value={formData.email} onChange={handleChange} variant="outlined" labelVariant="body2" isRequired />
      </Grid>
    </form>
  );
};

export default AddDisciple;
