import React, {useEffect, useState} from 'react';
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem} from '@mui/material';
import useTranslations from '../../hooks/useTranslation';
import useAuthStore from '../../store/authStore';
import Loader from '../../components/common/Loader';
import AdminStructure from './common/AdminStructure';

const DraftDay = () => {
  const {translate} = useTranslations();
  const {selectedOrganization} = useAuthStore(state => state);
  const [loading, setLoading] = useState(false);
  const [adminLoader, setAdminLoader] = useState(false);
  const [searchDraftDay, setSearchDraftDay] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);

  const disciples = [
    {id: 1, name: 'Bilbo Baggins', group: 'Main Campus', gender: 'Male', location: 'InPerson', choice1: 'Tuesday PM', choice2: 'Wednesday AM'},
    {id: 2, name: 'Tommy Hilfiger', group: 'Main Campus', gender: 'Male', location: 'Virtual', choice1: 'Tuesday PM', choice2: 'Wednesday AM'},
    {id: 3, name: 'Poppy Perkins', group: 'Main Campus', gender: 'Female', location: 'InPerson', choice1: 'Monday AM', choice2: 'Wednesday AM'},
    {id: 4, name: 'Judy Law', group: 'Main Campus', gender: 'Female', location: 'Virtual', choice1: 'Friday PM', choice2: 'Sunday AM'},
    {id: 5, name: 'Freddie Mercury', group: 'Main Campus', gender: 'Female', location: 'Acworth', choice1: 'Tuesday PM', choice2: 'Wednesday AM'}
  ];

  const expandedDetails = {
    5: [
      {group: 'Michaels Records', gender: 'Coed', location: 'Acworth', choice1: 'Tuesday PM', choice2: ''},
      {group: 'Mary’s Group', gender: 'Girls', location: 'Kennesaw', choice1: 'Tuesday PM', choice2: ''},
      {group: 'Mongo’s Group', gender: 'Girls', location: 'Acworth', choice1: 'Tuesday PM', choice2: 'Thursday PM'}
    ]
  };

  useEffect(() => {
    setSearchDraftDay(disciples);
  }, []);

  const toggleRow = id => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  return (
    <div style={{backgroundColor: '#f9f9f9', height: '100vh', width: '100vw', overflow: 'hidden'}}>
      <AdminStructure isSearch isAddingDraftDay handleAddClick={() => console.log('Add Draft Disciple')} heading={translate('Draft Day')}>
        {loading || adminLoader ? (
          <Loader color="#9e0001" />
        ) : (
          <Box sx={{px: 2, width: '95%', pb: 5}}>
            <TableContainer component={Paper} sx={{borderRadius: '10px', boxShadow: 2}}>
              <Table>
                <TableHead>
                  <TableRow sx={{backgroundColor: '#f5f5f5', fontWeight: 'bold'}}>
                    <TableCell sx={{color: 'black'}}>{translate('Disciple')}</TableCell>
                    <TableCell sx={{color: 'black'}}>{translate('Assign Group')}</TableCell>
                    <TableCell sx={{color: 'black'}}>{translate('Gender')}</TableCell>
                    <TableCell sx={{color: 'black'}}>{translate('Location')}</TableCell>
                    <TableCell sx={{color: 'black'}}>{translate('1st Choice')}</TableCell>
                    <TableCell sx={{color: 'black'}}>{translate('2nd Choice')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchDraftDay.map((disciple, index) => (
                    <React.Fragment key={disciple.id}>
                      {/* Main Row */}
                      <TableRow sx={{backgroundColor: index % 2 === 0 ? '#fff' : '#fdeaea', cursor: 'pointer'}} onClick={() => toggleRow(disciple.id)}>
                        <TableCell sx={{color: 'black'}}>{disciple.name}</TableCell>
                        <TableCell>
                          <Select value={disciple.group} variant="standard" sx={{minWidth: 120, color: 'black'}}>
                            <MenuItem value="Main Campus">Main Campus</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell sx={{color: 'black'}}>{disciple.gender}</TableCell>
                        <TableCell sx={{color: 'black'}}>{disciple.location}</TableCell>
                        <TableCell sx={{color: 'black'}}>{disciple.choice1}</TableCell>
                        <TableCell sx={{color: 'black'}}>{disciple.choice2}</TableCell>
                      </TableRow>

                      {/* Expanded Row */}
                      {expandedRow === disciple.id && expandedDetails[disciple.id] && (
                        <TableRow>
                          <TableCell sx={{backgroundColor: '#fff', paddingLeft: 2, fontWeight: 'bold', color: 'black'}}>Assign {disciple.name}:</TableCell>
                          <TableCell colSpan={5} sx={{backgroundColor: '#fff', padding: 1}}>
                            <Table size="small">
                              <TableBody>
                                {expandedDetails[disciple.id].map((detail, idx) => (
                                  <TableRow key={idx} sx={{backgroundColor: idx % 2 === 0 ? '#f9f9f9' : '#fff'}}>
                                    <TableCell sx={{color: 'black'}}>{detail.group}</TableCell>
                                    <TableCell sx={{color: 'black'}}>{detail.gender}</TableCell>
                                    <TableCell sx={{color: 'black'}}>{detail.location}</TableCell>
                                    <TableCell sx={{color: 'black'}}>{detail.choice1}</TableCell>
                                    <TableCell sx={{color: 'black'}}>{detail.choice2}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </AdminStructure>
    </div>
  );
};

export default DraftDay;
