import React, {useEffect, useState} from 'react';
import {Box, useMediaQuery, Checkbox, RadioGroup, Radio, FormControl, Typography, FormControlLabel} from '@mui/material';
import ItemList from './common/ItemList';
import ItemDetails from './common/Details';
import {addDisciple, archiveGroupApi, deleteGroupApi, getAdminGroupsApi, getAdminLeadersGroupApi, getGroupDiscipleApi, getMeetings, updateDiscipleApi} from '../../api';
import AdminStructure from './common/AdminStructure';
import NoDataFoundMessage from '../../components/common/NoDataFoundMessage';
import Loader from '../../components/common/Loader';
import moment from 'moment';
import {capitalizeWords, validateEmail} from '../../utils';
import {CONSTANTS} from '../../constants';
import useTranslations from '../../hooks/useTranslation';
import AddGroupModal from './common/AddGroupModal';
import Modal from '../../components/common/Modal';
import AddDisciple from './components/AddDisciple';
import StyledSelectInput from './components/StyledSelectInput';
import useAuthStore from '../../store/authStore';
import {useNavigate} from 'react-router-dom';
function Groups() {
  const {translate} = useTranslations();
  const navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState();
  const [groups, setGroups] = useState([]);
  const [meetings, setMeetings] = useState([]);
  const [disciples, setDisciples] = useState([]);
  const [loadingPage, setLoadingPage] = useState(false);
  const [leaders, setLeaders] = useState([]);
  const [searchGroups, setSearchGroups] = useState([]);
  const [groupLeader, setGroupLeader] = useState();
  const [loader, setLoader] = useState(false);
  const {selectedOrganization} = useAuthStore(state => state);
  const [selectDisciple, setSelectDisciple] = useState('');
  const [invite, setInvite] = useState(false);
  const [isAddDiscipleOpen, setIsAddDiscipleOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteOption, setDeleteOption] = useState('group');
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
  const [disData, setDisData] = useState({
    name: '',
    phone: '',
    email: ''
  });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [disciplesList, setDisciplesList] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  // Handler for Deleting the group (opens confirmation modal)
  const handleDeleteGroup = async () => {
    try {
      setDeleteLoader(true);
      const groupId = selectedGroup.id;
      const res = await deleteGroupApi(groupId, deleteOption);
      if (res.data.status === 200) {
        setSelectedGroup(null);
        handleMenuClose();
        setDeleteLoader(false);
        getGroups();
        getLeaders();
      }
    } catch (error) {
      setDeleteLoader(false);
    }
  };

   // Handler for Archieve the group (opens confirmation modal)
   const handleArchiveGroup = async () => {
    try {
      setDeleteLoader(true);
      const groupId = selectedGroup.id;
      const res = await archiveGroupApi(groupId, {isMarkGroupAsArchived: true});
      if (res.data.status === 200) {
        setSelectedGroup(null);      
        setDeleteLoader(false);
        getGroups();
        getLeaders();
      }
      setIsArchiveModalOpen(false);
    } catch (error) {
      setDeleteLoader(false);
    }
  };
  const resetData = () => {
    setDisData({
      name: '',
      phone: '',
      email: ''
    });
    setInvite(false);
    setSelectDisciple('');
  };

  const handleDiscipleSave = async () => {
    let res;
    try {
      setButtonLoader(true);
      if (selectDisciple) {
        const data = {
          group_id: selectedGroup.id,
          is_notify: invite
        };
        res = await updateDiscipleApi(selectDisciple, data);
      } else {
        const data = {
          first_name: disData.name.split(' ')[0] || '',
          last_name: disData.name.split(' ')[1] || '',
          email: disData.email,
          mobile_number: disData.phone,
          send_invite: true,
          group_id: selectedGroup.id,
          organization_id: selectedOrganization.id
        };
        res = await addDisciple(data);
      }
      if (res.data.status === 200) {
        setIsAddDiscipleOpen(false);
        resetData();
        handleGroupClick(selectedGroup);
      }
      setButtonLoader(false);
    } catch (error) {
      setButtonLoader(false);
    }
  };

  const handleMenuClose = e => {
    if (e) {
      e.stopPropagation();
    }
    setIsModalOpen(false);
    setIsAddDiscipleOpen(false);
    setIsArchiveModalOpen(false);
  };

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('md'));
  const handleOpen = () => setIsAddGroupModalOpen(true);
  const handleGroupClick = async group => {
    groupMeetingsById(group?.id);
    getGroupDisciplesById(group?.id);
    getGroupLeader(group?.id);
    setSelectedGroup(group);
  };

  useEffect(() => {
    let activeGroup;
    if (selectedGroup) {
      activeGroup = selectedGroup;
    } else {
      activeGroup = groups.find(group => group.status === 'ACTIVE');
    }
    if (activeGroup) {
      handleGroupClick(activeGroup);
    }
  }, [groups]);

  const getGroups = async () => {
    try {
      setLoadingPage(true);
      const resp = await getAdminGroupsApi(selectedOrganization.id);
      if (resp?.status == 200) {
        setGroups(resp?.data?.data);
        setSearchGroups(resp?.data?.data);
      }
      setLoadingPage(false);
    } catch (error) {
      setLoadingPage(false);
    }
  };

  const groupMeetingsById = async id => {
    setLoader(true);
    try {
      const res = await getMeetings(id);
      if (res?.data?.status === 200) {
        setMeetings(res?.data?.data);
      } else {
        setMeetings({});
      }
      setLoader(false);
    } catch (error) {
      setMeetings({});
      setLoader(false);
    }
  };

  const getGroupDisciplesById = async id => {
    setLoader(true);
    try {
      const res = await getGroupDiscipleApi(id);
      if (res?.data?.status === 200) {
        setDisciples(res?.data?.data);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const getLeaders = async () => {
    try {
      const res = await getAdminLeadersGroupApi(selectedOrganization.id);
      if (res?.data?.status == 200) {
        setLeaders(res?.data?.data);
      }
    } catch (error) {}
  };

  const getGroupLeader = async id => {
    try {
      const resp = await getAdminLeadersGroupApi(id, 'leader');
      if (resp?.data?.status == 200) {
        setGroupLeader(resp?.data?.data);
      }
    } catch (error) {}
  };

  const searchHandler = value => {
    if (value.length === 0) {
      setGroups(searchGroups);
    } else {
      const filteredGroups = searchGroups.filter(group => group?.name?.toLowerCase().includes(value.toLowerCase()));
      setGroups(filteredGroups);
    }
  };

  const getDiscipleWithNoGroupHandler = async () => {
    try {
      const res = await getGroupDiscipleApi('', selectedOrganization.id);
      if (res.data.status === 200) {
        setDisciplesList(
          res?.data?.data
            ?.map(disciple => ({
              label: disciple.name,
              value: disciple.id
            }))
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    getGroups();
    getLeaders();
    getDiscipleWithNoGroupHandler();
  }, [selectedOrganization]);

  // Calculate the counts of each status group
  const activeGroupsCount = groups ? groups.filter(group => group.status === 'ACTIVE').length : 0;
  const completedGroupsCount = groups ? groups.filter(group => ['COMPLETED'].includes(group.status)).length : 0;
  return (
    <>
      <Box>
        <div
          style={{
            backgroundImage: 'none !important',
            backgroundColor: '#f9f9f9',
            height: '100vh',
            width: '100vw',
            overflowY: isSmallScreen ? 'auto' : 'hidden'
          }}
        >
          <AdminStructure isSearch isAddingGroup handleAddClick={() => handleOpen()} searchHandler={searchHandler} heading={translate('Groups')}>
            {loadingPage ? (
              <Loader color="#9e0001" />
            ) : groups?.length === 0 ? (
              <NoDataFoundMessage mt={5} message={translate('There is no group for this organization')} />
            ) : (
              <>
                <Box
                  display={'flex'}
                  px={2}
                  gap={2}
                  sx={{
                    height: {
                      md: '88vh',
                      xs: 'auto',
                      sm: 'auto'
                    },
                    flexDirection: {
                      md: 'row',
                      sm: 'column',
                      xs: 'column'
                    },
                    justifyContent: {
                      md: 'space-between'
                    }
                  }}
                >
                  <Box
                    width="85%"
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    sx={{
                      marginTop: {
                        xs: '6px',
                        sm: '6px',
                        md: 0
                      }
                    }}
                  >
                    <ItemList isCompleted items={groups?.filter(group => ['ACTIVE'].includes(group.status))} handleClick={handleGroupClick} selectedItem={selectedGroup} height={'70%'} title={`${translate('Active Groups')} (${activeGroupsCount})`} moreVert={false} reloadData={getGroups} />
                    <ItemList isCompleted items={groups?.filter(group => ['COMPLETED'].includes(group.status))} handleClick={handleGroupClick} selectedItem={selectedGroup} height={'30%'} title={`${translate('Completed Groups')} (${completedGroupsCount})`} moreVert={false} reloadData={getGroups} />
                  </Box>
                  <Box
                    width="68%"
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    sx={{
                      justifyContent: {
                        md: 'space-between'
                      }
                    }}
                  >
                    {selectedGroup && (
                      <>
                        <ItemDetails
                          leaders={leaders}
                          height="45%" // set height for md screens (xs and sm are defaulted to auto)
                          title={selectedGroup?.name}
                          level={selectedGroup?.level}
                          editIcon={true}
                          deleteIcon={true}
                          archiveIcon ={selectedGroup?.status === 'COMPLETED'? true: false }
                          selectedGroup={selectedGroup}
                          setIsEditModalOpen={setIsEditModalOpen}
                          setIsArchiveModalOpen={setIsArchiveModalOpen}
                          setIsModalOpen={setIsModalOpen}
                          handleGroupClick={handleGroupClick}
                          details={[
                            {
                              name: translate('Start Date: '),
                              detail: meetings?.starting_time ? moment(meetings?.starting_time, 'YYYY-MM-DD').format(CONSTANTS.DATE_FORMAT) : 'NA',
                              fontWeight: '600'
                            },
                            {
                              name: translate('Day of Week: '),
                              detail: meetings?.meeting_day ? capitalizeWords(meetings?.meeting_day) : 'NA',
                              fontWeight: '600'
                            },
                            {
                              name: translate('Location: '),
                              detail: meetings?.meeting_place ? meetings?.meeting_place : 'NA',
                              fontWeight: '600'
                            },
                            {
                              name: translate('Number of Weeks: '),
                              detail: meetings?.meeting_weeks ? meetings?.meeting_weeks : 'NA',
                              mb: '8px',
                              fontWeight: '600'
                            },
                            {
                              name: translate('Completed: '),
                              detail: meetings?.completed_disciples?.length ? meetings?.completed_disciples?.length : '0',
                              fontWeight: '600'
                            },
                            {
                              name: translate('Dropped: '),
                              detail: meetings?.dropped_disciples?.length ? meetings?.dropped_disciples?.length : '0',
                              mb: '8px',
                              fontWeight: '600'
                            },
                            {
                              name: translate('Leader: '),
                              icon: selectedGroup?.status !== 'COMPLETED',
                              leaders: groupLeader,
                              fontWeight: '600'
                            }
                          ]}
                          loader={loader}
                          setSelectedGroup={setSelectedGroup}
                        />
                        <ItemList isEmailIcon title={`${selectedGroup?.name} ${translate('Disciples')}`} items={disciples} handleClick={item => navigate(`/admin-dashboard/disciples#${item?.user_id}`)} selectedItem={() => {}} height="50%" marginBottom={isSmallScreen ? 2 : 0} loader={loader} isAddingDisciple={true} showDeleteIcon={true} reloadData={() => handleGroupClick(selectedGroup)} handleAddClick={() => setIsAddDiscipleOpen(true)} selectedGroup={selectedGroup} reload={getGroups}/>
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </AdminStructure>
        </div>
      </Box>
      <AddGroupModal open={isAddGroupModalOpen} setOpen={setIsAddGroupModalOpen} getData={getGroups} />
      <AddGroupModal
        group={{...selectedGroup, ...meetings, leader: groupLeader?.id, group_id: selectedGroup?.id}}
        isEdit
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        getData={type => {
          if (type === 'reload') {
            getGroups();
            //setSelectedGroup(null);
          } else {
            handleGroupClick(selectedGroup);
          }
        }}
      />

      {/* modal for Add disciple to group */}
      <Modal
        open={isAddDiscipleOpen}
        onClose={handleMenuClose}
        title={translate('Add Disciple to New Group')}
        modalbgColor="#ffffff"
        modalTextColor="#000000"
        modalTitleColor="#d32f2f"
        buttons={[
          {
            name: translate('Cancel'),
            onClick: () => {
              setIsAddDiscipleOpen(false);
              resetData();
            },
            buttonBgColor: '#ffffff',
            buttonTextColor: 'black',
            buttonHoverColor: '#f5f5f5',
            type: 'outlined'
          },
          {
            name: translate('Save'),
            onClick: () => handleDiscipleSave(),
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            loading: buttonLoader,
            disabled: buttonLoader || !(selectDisciple || (disData.name.trim() && validateEmail(disData.email.trim())))
          }
        ]}
      >
        <FormControl fullWidth>
          <StyledSelectInput firstOption={{ value: "", label: translate("No Selection") }} optionArray={disciplesList} labelId="disciple-select-label" id="disciple-select" value={selectDisciple} onChange={e => setSelectDisciple(e.target.value)} displayEmpty />
        </FormControl>
        <Typography variant="body2" sx={{mt: -2, color: '#888888'}}>
          {translate('Select Existing Disciple')}
          <span style={{color: '#d32f2f', marginLeft: '2px'}}>*</span>
        </Typography>
        <FormControlLabel
          sx={{
            '& .MuiFormControlLabel-label': {
              color: '#000000 !important'
            }
          }}
          control={
            <Checkbox
              checked={invite}
              onChange={e => setInvite(e.target.checked)}
              sx={{
                color: '#000000 !important', // Unchecked color
                '&.Mui-checked': {
                  color: '#000000 !important' // Checked color
                }
              }}
            />
          }
          label={translate('Send Invite to Disciple')}
        />
        <Typography fontWeight="800" fontSize={'16px'} color={'#000000'} mb={2}>
          {translate('OR')}
        </Typography>
        <Typography fontWeight="800" fontSize={'16px'} color={'#d32f2f'} mb={2}>
          {translate('Invite New Disciple')}
        </Typography>
        <AddDisciple setFormData={setDisData} formData={disData} disabled={selectDisciple} />
      </Modal>

      {/* NEW DELETE GROUP MODAL FOR PHASE 3 */}
      <Modal
        open={isModalOpen}
        onClose={handleMenuClose}
        title={translate('Delete Group')}
        modalbgColor={'#ffffff'}
        modalTextColor={'#000000'}
        modalTitleColor={'#d32f2f'}
        buttons={[
          {name: translate('Cancel'), onClick: handleMenuClose, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {
            name: translate('Delete'),
            onClick: () => handleDeleteGroup(),
            loading: deleteLoader,
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
            disabled: !deleteOption
          } // Disable Delete if no option is selected
        ]}
      >
        <Typography sx={{mb: 2, color: '#000000'}}>{translate('If you are simply deleting a group, make sure you have moved disciples to new groups before proceeding.')}</Typography>
        <Typography sx={{mb: 2, color: '#000000'}}>{translate('Are you certain that you want to delete this group? This action cannot be undone!')}</Typography>
        <FormControl component="fieldset" sx={{'& .MuiFormControlLabel-label': {color: '#000000', fontSize: '1rem'}}}>
          <RadioGroup
            value={deleteOption}
            onChange={e => setDeleteOption(e.target.value)}
            sx={{
              color: '#000000 !important',
              '& .MuiFormControlLabel-label': {
                color: '#000000 !important'
              }
            }}
          >
            <FormControlLabel
              value="group"
              control={
                <Radio
                  sx={{
                    color: '#000000 !important',
                    '&.Mui-checked': {
                      color: '#000000 !important'
                    }
                  }}
                />
              }
              label={translate('Only delete group and group data.')}
            />
            <FormControlLabel
              value="disciples"
              control={
                <Radio
                  sx={{
                    color: '#000000 !important',
                    '&.Mui-checked': {
                      color: '#000000 !important'
                    }
                  }}
                />
              }
              label={translate('Delete group and all disciples from database.')}
            />
          </RadioGroup>
        </FormControl>
      </Modal>

      {/* ARCHIEVE GROUP MODAL */}
      <Modal
        open={isArchiveModalOpen}
        onClose={handleMenuClose}
        title={translate('Archive Group')}
        modalbgColor={'#ffffff'}
        modalTextColor={'#000000'}
        modalTitleColor={'#d32f2f'}
        buttons={[
          {name: translate('Cancel'), onClick: handleMenuClose, buttonBgColor: '#ffffff', buttonTextColor: 'black', buttonHoverColor: '#f5f5f5', type: 'outlined'},
          {
            name: translate('Archive'),
            onClick: () => handleArchiveGroup(),
            loading: deleteLoader,
            buttonBgColor: '#d32f2f',
            buttonTextColor: '#ffffff',
            buttonHoverColor: '#b71c1c',
          }
        ]}
      >
        <Typography sx={{color: '#000000'}}>{translate('Are you sure you want to mark group as archived?')}</Typography>
      </Modal>
    </>
  );
}

export default Groups;
